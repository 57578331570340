class PerfilModel {
    constructor(titulo, descricao, uuidempresa, status, permissoes) {
        this.titulo = titulo
        this.descricao = descricao;
        this.uuidempresa = uuidempresa;
        this.status = status;
        this.permissoes = permissoes;
    }
}

export default PerfilModel