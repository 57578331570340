import React, { useState, useEffect, useRef } from "react";
import { format } from 'date-fns';
import { Navigate, Route, Link, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FaPencil, FaX } from "react-icons/fa6";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Dropdown from 'react-bootstrap/Dropdown';
import RoboService from "../../services/robo.service";
import tokenService from "../../services/token.service";
import getStatus from "../../enum/processamento.enum";

const MySwal = withReactContent(Swal)

const RequestBot = () => {
  const inputRef = useRef(null);
  const state = {
    button: 1
  };
  // Definicao do formulario
  const form = useRef();
  // login para checagem
  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loadPage = async () => {
      // Auth
      const loggedInUser = tokenService.getUserData();
      if (loggedInUser) {
        setauthenticated(loggedInUser);
        setUUidEmp(loggedInUser.uuidEmpresa);
      }
      //  Recuperar listas
      // recuperar lista de requests em processamento
      const listaLog = await RoboService.getLogsProcessamento(loggedInUser.uuid);
      
      setProcessingList(listaLog.data.lista);
    }
    loadPage();
  }, []);


  // Alvo da pesquisa
  const [searchTarget, setTarget] = useState("");
  const [searchType, setType] = useState("");
  const [botType, setBotType] = useState("");
  // Dados Insta
  const [userIns, setUserIns] = useState("");
  const [passIns, setPassIns] = useState("");
  const [uuidEmpresa, setUUidEmp] = useState("");
  // Auxiliares 
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [requestList, setItemtoList] = useState([]);
  const [processingList, setProcessingList] = useState([]);

  /** helper switch bot */
  const [typeSwitch, setTypeSwitch] = useState("");

  // checagem de obrigatorio
  const required = (value) => {
    if (!value) {
      return (
        <div className="invalid-feedback d-block">
          Campos obrigatorios
        </div>
      );
    }
  };

  const handleAddRequest = () => {
    setItemtoList((prevItens) => [
      ...prevItens,
      {
        tipo: searchType,
        target: searchTarget,
        robo: botType
      }
    ])
  }

  const handleDelete = (e) => {
    requestList.splice(e, 1);
  }


  const onChangeTarget = (e) => {
    const target = e.target.value;
    setTarget(target);
  };

  const onChangeType = (e) => {
    const target = e.target.innerHTML.substr(0, 1);
    setType(target);
  };

  const onChangeBotType = (f) => {
    const tg = f.target.innerText;
    setTypeSwitch(tg);
    if (tg === "TikTok") {
      setType("-");
    }
    setBotType(tg);
  }

  /** formatar data  */
  const formatDate = (dt) => {
    var fixedDate;
    // fixedDate = dt
    let fx = new Date(dt).toLocaleString();
    let dia = fx.split(",")[0];
    let hora = fx.split(",")[1];
    return dia + " - " + hora;
  }

  const handleLogin = (e) => {
    e.preventDefault();

    if (state.button === "Executar") {
      setMessage("");
      setLoading(true);
      form.current.validateAll();
      if (searchTarget !== "" && searchTarget !== undefined && requestList.length > 0) {

        /** vamos separar qual eh TikTok e qual eh Instagram */
        let instaRequest = requestList.filter((x) => { if (x.robo === "Instagram") { return x } });
        let tiktokRequest = requestList.filter((x) => { if (x.robo === "TikTok") { return x } });

        /** agora iremos checar se tem requests a serem feitas  e realizar elas */
        if (instaRequest.length > 0) {

          const loggedInUser = tokenService.getUserData();
          let list = instaRequest.map((pa) => {
            return pa.target
          })
          let data = {
            searchList: list,
            userUuid: loggedInUser.uuid
          }
          RoboService.postGerarRequestInstagram(data).then(
            (dt) => {
              if (dt.status == 200) {
                MySwal.fire('Sucesso', dt.data.message, 'success');
                setLoading(false);
              } else {
                MySwal.fire('Erro', dt.message, 'error');
              }
            }, (error) => {
              MySwal.fire('Erro', error.response.data.message, 'error');
              setLoading(false);
            });
        }

        /** agora tiktok tbm */
        if (tiktokRequest.length > 0) {

          const loggedInUser = tokenService.getUserData();
          let data = {
            searchList: tiktokRequest,
            userUuid: loggedInUser.user
          }
          RoboService.postGerarRequestTiktok(data).then(
            (dt) => {
              if (dt.status == 200) {
                MySwal.fire('Sucesso', dt.data.message, 'success');
                setLoading(false);
              } else {
                MySwal.fire('Erro', dt.message, 'error');
              }
            }, (error) => {
              MySwal.fire('Erro', error.response.data.message, 'error');
              setLoading(false);
            });
        }


      } else {
        setLoading(false);
        MySwal.fire('Atenção', "Campo obrigatório não preenchido", 'warning');
      }
    }
    if (state.button === "Adicionar") {
      setItemtoList((prevItens) => [
        ...prevItens,
        {
          tipo: searchType,
          target: searchTarget,
          robo: botType
        }
      ])
    }


  };

  const cancelarRequest = (request) => {
    setMessage("");
    setLoading(true);
    let data = request;
    RoboService.cancelarRequest(data).then(
      (dt) => {
        if (dt.status == 200) {
          MySwal.fire('Sucesso', dt.data.message, 'success');
          setLoading(false);
        } else {
          MySwal.fire('Erro', dt.message, 'error');
        }
      }, (error) => {
        MySwal.fire('Erro', error.response.data.message, 'error');
        setLoading(false);
      });
  }



  const reprocessar = (request) => {

    setMessage("");
    setLoading(true);

    /** vamos separar qual eh TikTok e qual eh Instagram */
    let instaRequest = requestList.filter((x) => { if (x.robo === "Instagram") { return x } });
    let tiktokRequest = requestList.filter((x) => { if (x.robo === "TikTok") { return x } });

    /** agora iremos checar se tem requests a serem feitas  e realizar elas */
    if (instaRequest.length > 0) {

      const loggedInUser = tokenService.getUserData();
      let list = instaRequest.map((pa) => {
        return pa.target
      })
      let data = {
        searchList: list,
        userUuid: loggedInUser.uuid
      }
      RoboService.postGerarRequestInstagram(data).then(
        (dt) => {
          if (dt.status == 200) {
            MySwal.fire('Sucesso', dt.data.message, 'success');
            setLoading(false);
          } else {
            MySwal.fire('Erro', dt.message, 'error');
          }
        }, (error) => {
          MySwal.fire('Erro', error.response.data.message, 'error');
          setLoading(false);
        });
    }

    /** agora tiktok tbm */
    if (tiktokRequest.length > 0) {

      const loggedInUser = tokenService.getUserData();
      let data = {
        searchList: tiktokRequest,
        userUuid: loggedInUser.user
      }
      RoboService.postGerarRequestTiktok(data).then(
        (dt) => {
          if (dt.status == 200) {
            MySwal.fire('Sucesso', dt.data.message, 'success');
            setLoading(false);
          } else {
            MySwal.fire('Erro', dt.message, 'error');
          }
        }, (error) => {
          MySwal.fire('Erro', error.response.data.message, 'error');
          setLoading(false);
        });
    }
  };

  const tentarNovamente = (req) => {
    let request = {
      robo: req.tipo,
      target: req.alvo,
      tipo: "-"
    }
    requestList.push(request);
    handleLogin(request);
  };


  const navigate = useNavigate();
  const goToResultado = (req) => {
    navigate({
      pathname: '/bot/response',
      search: `?uuid=${uuidEmpresa}&usuario=${req.alvo}&tipo=${req.tipo}`,
    });
  }

  if (!authenticated) {
    // Redirect
    <Navigate replace to="/login" />;
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>Requisitar Execucao do BOT</h3>
          <p style={{ color: "#000000" }}>
            Você pode selecionar o tipo de bot que deseja utilizar Tiktok ou Instagram
          </p>
        </header>

        <Form onSubmit={handleLogin} ref={form}>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="tipo">Tipo Robo</label>
                <Dropdown name="Target"
                  value={botType}
                  onChange={onChangeBotType}
                  validations={[required]}>
                  <Dropdown.Toggle variant="success">
                    {typeSwitch == "Instagram" ? "Instagram" : typeSwitch == "TikTok" ? "TikTok" : "Selecione"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={onChangeBotType} value="Instagram">
                      Instagram
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={onChangeBotType} value="TikTok">
                      TikTok
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {
              typeSwitch === "Instagram" ?
                (
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="tipo">Tipo alvo</label>
                      <Dropdown name="Target"
                        value={searchType}
                        onChange={onChangeType}
                        validations={[required]}>
                        <Dropdown.Toggle variant="success">
                          {searchType == "#" ? "# - HashTag" : searchType == "@" ? "@ - Perfil" : "Selecione"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#" onClick={onChangeType} value="#">
                            # - Hashtag
                          </Dropdown.Item>
                          <Dropdown.Item href="#" onClick={onChangeType} value="@">
                            @ - Perfil
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>)
                :
                null
            }
            <div className="col-md-5">
              <div className="form-group">
                <label htmlFor="email">Alvo da pesquisa</label>
                <Input
                  type="text"
                  className="form-control"
                  name="Target"
                  value={searchTarget}
                  onSelect={onChangeTarget}
                  validations={[required]}
                />
              </div>
            </div>
            <div className="col-md-3 spacer-top-nolabel" style={{ marginTop: "15px !important" }}>
              <div className="form-group">
                <label htmlFor="email"></label>
                <button className="btn btn-primary btn-block" onClick={() => { state.button = "Adicionar" }}>
                  <span>Adicionar a lista</span>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group mt-4">
            <button className="btn btn-primary btn-block" onClick={() => { state.button = "Executar" }} disabled={loading || requestList.length == 0}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Executar Robo</span>
            </button>
          </div>
          <hr></hr>
          {
            requestList.length > 0 &&
            <div className="form-group mt-3">
              <div className="row">
                <h3> Lista de processamento </h3>
              </div>
              <div className="col-12">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Robô</th>
                      <th>Tipo</th>
                      <th>Alvo</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      requestList.map((request, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td> {request.robo}</td>
                              <td> {request.tipo}</td>
                              <td> {request.target}</td>
                              <td>
                                <Button ref={inputRef} variant="danger" onClick={() => {
                                  handleDelete(index)
                                }}>
                                  <FaX />
                                </Button>
                              </td>
                            </tr>
                          </>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          }
          {/* tarefas processamento */}
          <hr></hr>
          {
            processingList.length > 0 &&
            <div className="form-group mt-3">
              <div className="row">
                <h3> Tarefas em processamento </h3>
              </div>
              <div className="col-12">
                <Table responsive style={{ backgroundColor: '#ffffff' }}>
                  <thead>
                    <tr>
                      <th>Alvo</th>
                      <th>Tipo</th>
                      <th>Data Criacao</th>
                      <th>Atualizado em</th>
                      <th>Status</th>
                      <th>Observacoes</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      processingList
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                        .map((request, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{request.alvo}</td>
                                <td>{request.tipo}</td>
                                <td>{formatDate(request.createdAt)}</td>
                                <td>{formatDate(request.updatedAt)}</td>
                                <td>{getStatus(request.status)}</td>
                                <td>{request.statusDesc}</td>
                                {request.status === "F" && (
                                  <button className="btn btn-primary btn-block" onClick={() => { goToResultado(request) }}>
                                    <span>Resultados</span>
                                  </button>
                                )}
                                {request.status === "ER" && (
                                  <button variant="danger" className="btn btn-primary btn-block" onClick={() => { reprocessar(request) }}>
                                    <span>
                                      <p>Tentar Novamente</p>
                                    </span>
                                  </button>
                                )}
                                {(request.status === "C" && request.createdAt === new Date()) && (
                                  <button variant="danger" className="btn btn-primary btn-block" onClick={() => { cancelarRequest(request) }}>
                                    <span>
                                      <p>Cancelar</p>
                                    </span>
                                  </button>
                                )}
                              </tr>
                            </>
                          );
                        })
                    }

                  </tbody>
                </Table>
              </div>
            </div>
          }

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export default RequestBot;
