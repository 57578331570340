import React, { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import "../style/general.css";
import UserService from "../services/user.service";
import avisosService from "../services/avisos.service"
import ProdutoService from "../services/produtos.service";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import Form from "react-validation/build/form";
import avisosUtils from "../utils/avisosUtils"
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem'
import 'bootstrap/dist/css/bootstrap.css';
import parse from 'html-react-parser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import userUtils from "../utils/userUtils";
import dataUtils from "../utils/dataUtils";

const Home = () => {
  const [authenticated, setauthenticated] = useState(null);
  const [content, setContent] = useState("");
  const [productList, setProductList] = useState([]);
  const [productListBackup, setProductListBackup] = useState([]);
  const [categoriaProdutoList, setCategoriaProdutoList] = useState([]);
  const [avisosList, setAvisosList] = useState([]);
  const [filtroCat, setFiltroCat] = useState("all");
  const [showAvisos, setShowAvisos] = useState(false);
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [produtoDetalhes, setProdutoDetalhes] = useState({})

  const [showVencido, setShowVencido] = useState(false);
  const [userDataVencimento, setUserDataVencido] = useState("");


  const handleClose = () => {
    let listaAtual = localStorage.getItem("@elosworld:ListaAvisosLidos") !== null ? JSON.parse(localStorage.getItem("@elosworld:ListaAvisosLidos")) : [];
    let idsAvisos = avisosList.map((a) => a.uid);

    idsAvisos.forEach((id) => {
      if (!listaAtual.includes(id)) {
        listaAtual.push(id);
      }
    });

    localStorage.setItem("@elosworld:ListaAvisosLidos", JSON.stringify(listaAtual));

    setShowAvisos(false);
  }

  const handleCloseVencido = () => {
    setShowVencido(false);
  }

  const handleCloseDetalhes = () => {
    setShowDetalhes(false);
  }

  useEffect(() => {
    const loadPage = () => {
      // user data
      const loggedInUser = localStorage.getItem("@elosworld:token");
      if (loggedInUser) {
        setauthenticated(loggedInUser);
      }
      // product data
      ProdutoService.getListarAtivos().then((response) => {
        setProductList(response.data.lista);
        setProductListBackup(response.data.lista);
      })
      // categoria produto
      ProdutoService.getListarCategorias().then((res) => {
        if (res.data.lista) {
          let options = res.data.lista.map((d) => {
            return { value: d.id, label: d.nome }
          })
          options.unshift({ value: 'all', label: 'Todos' })
          setCategoriaProdutoList(options);
        }
      })
      // listar avisos
      avisosService.getListarAtivos().then((r) => {
        setAvisosList(r.data.lista);
        let avisosAtivos = avisosUtils.checarAvisosVistos(r.data.lista);
        if (avisosAtivos.length > 0) {
          setAvisosList(avisosAtivos);
          setShowAvisos(true)
        }
      })
      // validador de vencimento
      let getVencimento = userUtils.checarValidade();

      if (getVencimento.isVencido == true) {
        setUserDataVencido(getVencimento.dataVencimento);
        setShowVencido(true);

      }
    }
    loadPage();
  }, []);

  // Metodo para filtrar de acordo com a categoria
  const filtrarProdutos = (cat) => {
    setFiltroCat(cat.value);
    let lista = productListBackup;
    lista.filter((it) => {
      if (cat.value === 'all') {
        setProductList(productListBackup);
      } else {
        setProductList(productListBackup);
        let nova = productListBackup.filter((it) => {
          return it.idCategoriaProduto == cat.value;
        })
        setProductList(nova);
      }
    })
  }


  async function imageExists(url) {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok ? url : process.env.REACT_APP_API_URL + "/uploads/produtos/unknown.png";
    } catch {
      return process.env.REACT_APP_API_URL + "/uploads/produtos/unknown.png";
    }
  }

  async function checkImage(url) {
    try {
      const result = await imageExists(url);
      console.log(`Image exists at ${result}`);
      return result;
    } catch {
      console.log('Image does not exist');
      return process.env.REACT_APP_API_URL + "/uploads/produtos/unknown.png";
    }
  }

  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const loadImages = async () => {
      const productList = await ProdutoService.getListarAtivos();
      const imageUrls = {};
      productList.data.lista.forEach((product) => {
        imageUrls[product.imagem] = imageExists(process.env.REACT_APP_API_URL + "uploads/produtos/" + product.imagem);
      });
      setImageUrls(imageUrls);
    };
    loadImages();
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    pauseOnHover: true
  };

  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  function sanitizeHtml(str) {
    return str.replace(/<[^>]+>/g, '').trim();
  }

  if (!authenticated) {
    // Redirect
    <Navigate replace to="/login" />;
  } else {
    return (
      <>

        {/* Modal de Vencido  */}
        <Modal centered={true} show={showVencido} onHide={handleCloseVencido} dialogClassName="modal-90w" size="xl" scrollable={false}>
          <Modal.Header>
            <Modal.Title style={{ color: "#000000" }}>
              <h4>
                Aviso Importante: Renovação do Plano Necessária!
              </h4>
            </Modal.Title>
            <Button className="col-md-2 mdl-close-btn" onClick={handleCloseVencido}> X </Button>
          </Modal.Header>
          <Modal.Body>
            <br></br>
            <p>Gostaríamos de informar que o seu plano atual expirou no dia <strong>{dataUtils.formatarData(userDataVencimento)}</strong>.</p>
            <br></br>
            <p> Para continuar desfrutando dos nossos serviços sem interrupções, solicitamos que entre em contato com nosso suporte através do telefone o mais breve possível para verificar as opções de renovação disponíveis.</p>
            <br></br>
            <p>Estamos à disposição para ajudar em qualquer dúvida que possa surgir durante o processo. Agradecemos pela sua compreensão e esperamos continuar atendendo às suas necessidades.</p>
            <br></br>
            <p>Atenciosamente,</p>
            <br></br>
            Equipe de Suporte ao Cliente
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseVencido}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Fim modal vencido */}

        {/* Modal para Avisos */}
        <Modal centered={true} show={showAvisos} onHide={handleClose} dialogClassName="modal-90w" size="xl" scrollable={false}>
          <Modal.Header>
            <Modal.Title style={{ color: "#000000" }}>
              <h4>
                Quadro de Avisos
              </h4>
            </Modal.Title>
            <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="slider-container">
              <Slider
                ref={slider => {
                  sliderRef = slider;
                }}
                {...settings}
              >
                {
                  avisosList && avisosList.length > 0 && avisosList.map((aviso, index) => {
                    return (
                      <>
                        <div key={index + 1}>
                          <div style={{ padding: "5%" }}>
                            <h1 style={{ padding: "5%" }}>{aviso.titulo}</h1>
                            <span style={{ padding: "5%" }}>{parse(aviso.descricao)}</span>
                          </div>
                        </div>
                      </>
                    )
                  })
                }
              </Slider>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Fim modal avisos */}

        {/* Modal de detalhes */}
        <Modal centered={true} show={showDetalhes} onHide={handleCloseDetalhes} dialogClassName="modal-90w" size="xl" scrollable={true}>
          <Modal.Body>
            <Container>
              <Row>
                <Col md={4}>
                  {
                    produtoDetalhes.imagem ?
                      <Card.Img variant="top" src={process.env.REACT_APP_API_URL + "uploads/produtos/" + produtoDetalhes.imagem} />
                      :
                      <Card.Img variant="top" src={process.env.REACT_APP_API_URL + "uploads/produtos/unknown.png"} />
                  }
                </Col>
                <Col md={8}>
                  <Row>
                    <Col>
                      <h4>
                        {produtoDetalhes.titulo}
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      {
                        produtoDetalhes.descricao &&
                        parse(produtoDetalhes.descricao)
                      }
                    </Col>
                  </Row>
                  {
                    produtoDetalhes.video &&
                    <Row className="mt-3">
                      <Col>
                        <iframe width="350" height="315" src={produtoDetalhes.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      </Col>
                    </Row>
                  }
                  {
                    produtoDetalhes.tipo == "Link" &&
                    <Row className="mt-3">
                      <Col>
                        <Button size="md" style={{ height: '100%' }} variant="primary" href={produtoDetalhes.endereco}>Acessar</Button>
                      </Col>
                    </Row>
                  }
                  {
                    produtoDetalhes.tipo == "Executavel" &&
                    <Row className="mt-3">
                      <Col>
                        <Button size="md" style={{ height: '100%' }} variant="primary" href={produtoDetalhes.endereco}>Download</Button>
                      </Col>
                    </Row>
                  }

                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDetalhes}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Fim modal detalhes */}

        <div>
          <header className="jumbotron">
            <h3>Produtos Disponíveis</h3>
          </header>
          <Form>
            <div className="row">
              <div className="col-5"></div>
              <div className="col-3">
              </div>
              <div className="col-1 text-right mb-3" style={{ marginLeft: "-2%" }}>
                {/* <Button variant="primary" className="btn-custom" onClick={onFiltrar}>
                Filtrar
              </Button> */}
              </div>
              <div className="col-3">
                <div className="form-group">
                  <Select placeholder="Filtrar" options={categoriaProdutoList} onChange={(e) => filtrarProdutos(e)} />
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </Form>
          <>
            {
              categoriaProdutoList.map((cat, index) => {
                /** filtro para ver se tem produto nessa categoria */
                var pt = productList.filter((p) => {
                  if (p.idCategoriaProduto == cat.value) {
                    return p;
                  }
                })

                return (
                  <>

                    <div className="row" key={index}>
                      {
                        pt.length > 0 ?
                          <>
                            {cat.label !== "Todos" ?
                              <>
                                <div className="col-1"></div>
                                <div className="col-10">
                                  <h3 style={{ color: "#000000" }}>
                                    {cat.label}
                                  </h3>
                                </div>
                                <div className="col-1"></div>
                              </>
                              :
                              ""}
                            {
                              productList.map((product, index) => {
                                if (product.idCategoriaProduto == cat.value) {
                                  return (
                                    <>
                                      <div className="col-md-4">
                                        <Card style={{ width: '25rem' }}>
                                          {/* <Card.Img variant="top" src={imageExists(process.env.REACT_APP_API_URL+"public/uploads/produtos/"+product.imagem)} /> */}
                                          {
                                            product.imagem ?
                                              <Card.Img variant="top" src={process.env.REACT_APP_API_URL + "uploads/produtos/" + product.imagem} />
                                              :
                                              <Card.Img variant="top" src={process.env.REACT_APP_API_URL + "uploads/produtos/unknown.png"} />
                                          }

                                          <Card.Body>
                                            <Container>
                                              <Row>
                                                <Col>
                                                  <Card.Title>
                                                    {product.titulo}
                                                  </Card.Title>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <Card.Text>
                                                  {product.descricao.length > 40 ? `${sanitizeHtml(product.descricao).substring(0, 40)}...` : parse(product.descricao)}
                                                    {/* {parse(product.descricao)} */}
                                                  </Card.Text>
                                                </Col>
                                              </Row>
                                              <Row style={{ marginTop: "10%" }}>
                                                <Col md={6}>
                                                  <ButtonGroup className="d-flex">
                                                    {
                                                      product.tipo == "Link" &&
                                                      <Button size="md" style={{ height: '100%' }} variant="primary" href={product.endereco}>Acessar</Button>
                                                    }
                                                    {
                                                      product.tipo == "Executavel" &&
                                                      <Button size="md" style={{ height: '100%' }} variant="primary" href={product.endereco}>Download</Button>
                                                    }
                                                  </ButtonGroup>
                                                </Col>
                                                <Col md={6}>
                                                  <ButtonGroup className="d-flex">
                                                    {/* <div className="d-grid gap-2"> */}

                                                    <Button size="md" style={{ height: '100%' }} variant="primary" onClick={() => {
                                                      setShowDetalhes(true);
                                                      setProdutoDetalhes(product);
                                                    }}> Saiba Mais</Button>
                                                    {/* </div> */}
                                                  </ButtonGroup>
                                                </Col>
                                              </Row>
                                              {
                                                product.video &&
                                                <Row>
                                                  <Col md={12} className="mt-4 text-center">
                                                    <ButtonGroup className="d-flex">
                                                      <Button size="md" style={{ height: '100%' }} variant="primary" onClick={() => {
                                                        window.open(product.video, "_blank")
                                                      }}>Tutorial</Button>
                                                    </ButtonGroup>
                                                  </Col>
                                                </Row>
                                                // <iframe width="350" height="315" src={product.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                              }
                                            </Container>
                                          </Card.Body>
                                        </Card>
                                      </div >
                                    </>
                                  )
                                }
                              })
                            }

                          </>
                          :
                          ""
                      }
                    </div>
                  </>
                );
              })
            }
            {
              filtroCat == "all" &&
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <h3 style={{ color: "#000000" }}>
                    Outros
                  </h3>
                </div>
                <div className="col-1"></div>
              </div>
            }
            <div className="row">
              {
                // Vamos exibir os sem categoria tambem                            
                productList.map((rf) => {
                  if (rf.idCategoriaProduto == undefined || null) {
                    return (
                      <>
                        <div className="col-md-4">
                          <Card style={{ width: '25rem' }}>
                            {/* <Card.Img variant="top" src={imageExists(process.env.REACT_APP_API_URL+"public/uploads/produtos/"+product.imagem)} /> */}
                            {
                              rf.video ?
                                <iframe width="350" height="315" src={rf.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                :
                                rf.imagem ?
                                  <Card.Img variant="top" src={process.env.REACT_APP_API_URL + "uploads/produtos/" + rf.imagem} />
                                  :
                                  <Card.Img variant="top" src={process.env.REACT_APP_API_URL + "uploads/produtos/unknown.png"} />

                            }

                            <Card.Body>
                              <Card.Title>{rf.titulo}</Card.Title>
                              <Card.Text>
                                {rf.descricao.length > 40 ? `${rf.descricao.substring(0, 40)}...` : rf.descricao}
                              </Card.Text>

                              {
                                rf.tipo == "Link" &&
                                <Button style={{ height: '100%' }} variant="primary" href={rf.endereco}>Acessar</Button>
                              }
                              {
                                rf.tipo == "Executavel" &&
                                <Button style={{ height: '100%' }} variant="primary" href={rf.endereco}>Download</Button>
                              }
                              <Button style={{ height: '100%', marginLeft: "40%" }} variant="primary"> Saiba Mais</Button>
                            </Card.Body>
                          </Card>
                        </div >
                      </>
                    )
                  }
                })

              }
            </div>
          </>
        </div >
      </>
    );
  }
}

export default Home;
