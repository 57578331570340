import axios from "axios";
import tokenService from "./token.service";

const uuid = tokenService.getUserData();

axios.interceptors.request.use(
    config => {
        config.headers.Authorization = "123";
        config.headers.uuidEmpresa = uuid ? uuid.uuidEmpresa : null;
        return config;
    },
    error => Promise.reject(error)
)

export default axios;