class ProdutoModel {
    constructor(id, titulo, tipo, descricao, endereco, imagem, status) {
        this.id = id;
        this.titulo = titulo;
        this.tipo = tipo;
        this.descricao = descricao;
        this.endereco = endereco;
        this.imagem = imagem;
        this.status = status;
    }
}

export default ProdutoModel
