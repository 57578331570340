import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EmpresaService from "../../services/empresa.service";
import tokenService from "../../services/token.service";
import UsuarioModel from "../../models/usuario.model";
import Perfilervice from "../../services/perfil.service";
import AuthService from "../../services/auth.service";

const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const CadastrarUsuario = () => {

    const form = useRef();
    const checkBtn = useRef();

    /* useState para armazenar as informacoes */
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [authData, setAuth] = useState({});
    const [isAdmin, setAdmin] = useState(false);
    /** campos do form */
    const [email, setEmail] = useState(null);
    const [senha, setSenha] = useState(null);
    const [perfil, setPerfil] = useState({id: null, titulo: ""});
    const [uuidEmpresa, setConta] = useState("");
    const [perfilList, setPerfilList] = useState([]);
    const [contasList, setContasList] = useState([]);


    useEffect(() => {
        const loggedInUser = tokenService.getUserData();
        if (loggedInUser) {
            setAuth(loggedInUser);
            setConta(loggedInUser.uuidEmpresa);
            carregarPerfis(uuidEmpresa);
            if (loggedInUser.permissoes.find((pi) => {
                return pi.includes("super-admin");
            })) {
                // carregar o dropdown
                carregarContas();
                return setAdmin(true);
            } else {
                return setAdmin(false);
            }
        }
    }, []);

    /** Listar perfis */
    const carregarPerfis = (uuidConta) => {
        setPerfilList([]);
        setPerfil({})
        Perfilervice.getPerfil(uuidConta).then(
            (dt) => {
                setPerfilList(dt.data.lista);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    /** carregar contas e recarregar perfis */
    const carregarContas = () => {
        AuthService.getContas().then(
            (dt) => {
                setContasList(dt.data.lista);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    /* clicar para registrar */
    const onRegistrarUsuario = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            let userData = new UsuarioModel();
            Object.assign(userData, {                
                email: email,
                password: senha,
                idPerfil: perfil.id,
                status: 1
            })
            AuthService.postCadastrarUsuario(userData).then(
                (dt) => {
                    if (dt.status === 200) {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Sucesso!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'success'
                        });

                        // poe no localhost
                        //   let udt = tokenService.getUserData();
                        //   directCheck(udt.permissoes);            
                        //   window.location.reload();            
                    } else {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Atencao!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'warning'
                        })
                    }

                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }


    }

    /* Quando houver alteracao no campo */
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    }
    const onChangeSenha = (e) => {
        const senha = e.target.value;
        setSenha(senha);
    }
 

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h1>
                        Cadastrar novo usuario
                    </h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6"></div>
                <Form onSubmit={onRegistrarUsuario} ref={form}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            onChange={onChangeEmail}
                            validations={[required]}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Senha</label>
                        <Input
                            type="password"
                            className="form-control"
                            name="password"
                            value={senha}
                            onChange={onChangeSenha}
                            validations={[required]}
                        />
                    </div>
                    {isAdmin &&
                        < div className="form-group">
                            <label htmlFor="tipo">Conta Relacionada</label>
                            <Dropdown>
                                <Dropdown.Toggle variant="success">
                                    {uuidEmpresa.NomeConta ? uuidEmpresa.NomeConta : "Selecione"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                {
                                    contasList.map((conta, index) => {
                                        return (
                                            <>
                                                <Dropdown.Item key={index+conta.uuid} href="#" onClick={() => {
                                                    setConta(conta)
                                                    carregarPerfis(conta.uuid)
                                                 }} value={conta}>
                                                    {conta.NomeConta}
                                                </Dropdown.Item>
                                            </>
                                        )
                                    })
                                }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    }
                    < div className="form-group">
                        <label htmlFor="tipo">Perfil</label>
                        <Dropdown>
                            <Dropdown.Toggle variant="default">
                                {perfil.titulo}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    perfilList.map((perfil, index) => {
                                        return (
                                            <>
                                                <Dropdown.Item key={index+perfil.uuid} href="#" onClick={() => {
                                                    setPerfil(perfil)
                                                 }} value={perfil}>
                                                    {perfil.titulo}
                                                </Dropdown.Item>
                                            </>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary btn-block" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Cadastrar</span>
                        </button>
                    </div>

                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form >
            </div >
        </>
    );
}

export default CadastrarUsuario;