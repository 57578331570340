import axios from "./BaseService";

/**
 * Service responsável por perfil e permissões
 */

/**
 * Perfis
 */

/** Listar perfis Empresa */
const getPerfil = (uuidConta) => {
  return axios.post(process.env.REACT_APP_API_URL + "perfil/listar",{ uuidEmpresa: uuidConta});
};

/* registrar perfil */
const postRegistrarPerfil = (perfilDados) => {
    return axios.post(process.env.REACT_APP_API_URL + "perfil/registrar", perfilDados);
}

/* atualizar perfil */
const putAtualizarPerfil = (perfilDados) => {
    return axios.put(process.env.REACT_APP_API_URL + "perfil/atualizar", perfilDados);
}

const deleteRemoverPerfil = (uuid, id) => {
    return axios.delete(process.env.REACT_APP_API_URL + `perfil/remover/${uuid}/${id}`);
}

/**
 * Permissões
 */

/** Listar Permissões da empresa */
const getListarPermissoes = () => {
    return axios.get(process.env.REACT_APP_API_URL + "perfil/listar/permissoes")
}

/** registrar permissao */
const postRegistrarPermissao = (permissaoDados) => {
    return axios.post(process.env.REACT_APP_API_URL + "perfil/permissoes/registrar", permissaoDados);
}

/** atualizar permissao */
const putAtualizarPermissao = (permissaoDados) => {
    return axios.put(process.env.REACT_APP_API_URL + "perfil/permissoes/atualizar", permissaoDados);
}

/** remover permissao */
const deleteRemoverPermissao = (permissaoDados) => {
    return axios.delete(process.env.REACT_APP_API_URL + "perfil/permissoes/remover", permissaoDados);
}

/**
 * Permissoes X Perfil
 */

/** listar permissoes do perfil  */
const getListarPermissoesPerfil = (idEmpresa, idperfil) => {
    return axios.get(process.env.REACT_APP_API_URL + `perfil/permissoes/atribuidas/${idEmpresa}/${idperfil}`);
}

/** atribuir permissao ao perfil */
const postAtribuirPermissaoPerfil = (permissaoPerfil) => {
    return axios.post(process.env.REACT_APP_API_URL  + 'perfil/permissao/atribuir', permissaoPerfil);
}

/** remover permissao perfil  */
const deleteRemoverPermissaoPerfil = (permissaoPerfil) => {
    return axios.delete(process.env.REACT_APP_API_URL + "perfil/permissao", permissaoPerfil);
}

/** validar propriedade perfil */
const getValidarPropriedadePerfil = (idEmpresa, idPerfil) => {
    return axios.get(process.env.REACT_APP_API_URL + `perfil/perfil/validarpropriedade/${idEmpresa}/${idPerfil}`);
}

/** validar propriedade permissao */
const getValidarPropriedadePermissao = (idEmpresa, idPermissao) => {
    return axios.get(process.env.REACT_APP_API_URL  + `perfil/permissao/validarpropriedade/${idEmpresa}/${idPermissao}`);
}



const Perfilervice = {
    getPerfil,
    postRegistrarPerfil,
    putAtualizarPerfil,
    deleteRemoverPerfil,
    getListarPermissoes,
    postRegistrarPermissao,
    putAtualizarPermissao,
    deleteRemoverPermissao,
    getListarPermissoesPerfil,
    postAtribuirPermissaoPerfil,
    deleteRemoverPermissaoPerfil,
    getValidarPropriedadePerfil,
    getValidarPropriedadePermissao
}

export default Perfilervice;
