import tokenService from "../services/token.service";

/** Verifica se a data de validade foi atingida */
const checarValidade = () => {
    let userData = tokenService.getUserData();
    let isVencidoValidator = tokenService.getIsVencido();
    if(userData){
        // let isVencido = userData.isVencido ? userData.isVencido : true ;
        let dataVencimento = userData.dataVencimento;
        let obj;
        if (isVencidoValidator) {
            obj = {
                isVencido: true,
                dataVencimento: dataVencimento
            }
        } else {
            obj = {
                isVencido: false,
                dataVencimento: dataVencimento
            }
        }
        return obj
    } else {
        return {
            isVencido: true,
            dataVencimento: "undefined"
        }
    }
}

const userUtils = {
    checarValidade
}

export default userUtils;