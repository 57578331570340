import React, { useState, useEffect, useRef } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaPencil, FaX } from "react-icons/fa6";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../../style/general.css";
import PropTypes from 'prop-types';
import Perfilervice from "../../services/perfil.service";
import tokenService from "../../services/token.service";
import AuthService from "../../services/auth.service";
import PermissionUtil from "../../common/PermissionUtil";
import Modal from 'react-bootstrap/Modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import PerfilModel from "../../models/perfil.model";
import DualListBox from 'react-dual-listbox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import arrayUtils from "../../utils/arrayUtils";

/** estilo dos botoes  */
const buttonStyle = {
    marginLeft: "15px"
};

const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const ListarPerfis = () => {

    /* userefs */
    const form = useRef();
    const checkBtn = useRef();
    const inputRef = useRef(null);

    /** useStates */
    const [perfilLista, setPerfilList] = useState([]);
    const [perfilListaBackup, setPerfilListaBackup] = useState([]);
    const [contaLista, setContaList] = useState([]);
    const [Conta, setConta] = useState({});
    const [usuario, setUsuario] = useState("");
    const [isAdmin, setAdmin] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);

    /* form */
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [titulo, setTitulo] = useState("");
    const [descricao, setDescricao] = useState("");
    const [uuidConta, setUuid] = useState("");
    const [selected, setSelected] = useState([]);
    const [listaPermissoes, setListaPermissoes] = useState([]);
    const [permissoesPerfil, setPermissaoPerfil] = useState([]);
    const [perfil, setPerfil] = useState({})

    const [idAtualizar, setIdAtualizar] = useState("");
    const [tituloAtualizar, setTituloAtualizar] = useState("");
    const [descricaoAtualizar, setDescricaoAtualizar] = useState("");
    const [permissoesAtualizar, setPermissoesAtualizar] = useState([]);


    /** support Modal */
    const [show, setShow] = useState(false);
    const [showAtualizar, setShowAtualizar] = useState(false);
    const handleCloseAtualizar = () => setShowAtualizar(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDescricaoAtualizar = (e) => perfilAtualizar.descricao = e;
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [authData, setAuth] = useState({});

    const [filtroTipo, setTipoFiltro] = useState("");
    const [filtroValor, setValorFiltro] = useState("");

    const filtroList = [
        { label: "Titulo", value: "titulo" },
        { label: "Descrição", value: "descricao" }
    ]

    /** perfil atualizar */
    const [perfilAtualizar, setPerfilAtualizar] = useState("");


    /** Editor */
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setDescricao(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const onEditorStateAtualizarChange = (editorState) => {
        setEditorState(editorState)
        setDescricaoAtualizar(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };


    /** UseEffect para inicializar */
    useEffect(() => {
        let userData = tokenService.getUserData();
        setUsuario(userData);
        carregarPerfis(userData.uuidEmpresa);
        if (userData.permissoes.find((pi) => {
            return pi.includes("super-admin");
        })) {
            // carregar o dropdown
            carregarContas();
            return setAdmin(true);
        } else {
            return setAdmin(false);
        }
    }, []);

    /** Metodo de carga da lista  */
    const carregarPerfis = (uuidConta) => {
        setPerfilList([]);
        Perfilervice.getPerfil(uuidConta).then(
            (response) => {

                let list = response.data.lista.map((r) => {
                    return {
                        ...r,
                        selectedPermissoes: []
                    }
                })
                setPerfilList(list);
                setPerfilListaBackup(list);
            },
            (error) => {
                const aviso = (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Erro', aviso, 'error');
            });
    }

    /** carregar contas  */
    const carregarContas = () => {
        AuthService.getContas().then(
            (dt) => {
                setContaList(dt.data.lista);
            },
            (error) => {
                const aviso = (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Erro', aviso, 'error');
            }
        );
    }

    /** remover perfil */
    const handleDelete = (uuid, id, titulo) => {
        MySwal.fire({
            title: "Voce tem certeza?",
            text: `Ao confirmar voce ira remover o perfil ${titulo} do sistema`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar Remocao"
        }).then((result) => {
            if (result.isConfirmed) {
                Perfilervice.deleteRemoverPerfil(uuid, id).then((r) => {
                    if (r.status === 200) {
                        MySwal.fire({
                            title: "Sucesso!",
                            text: "Perfil removido",
                            icon: "success"
                        });
                        carregarPerfis();
                    } else {
                        MySwal.fire({
                            title: "Atencao!",
                            text: `Ocorreu o seguinte erro: ` + r,
                            icon: "error"
                        });
                    }
                }, (error) => {
                    MySwal.fire({
                        title: "Atencao!",
                        text: "Ocorreu o seguinte erro:  " + error,
                        icon: "error"
                    });
                })
            }
        });
    }

    // Cadastrar novo Perfil
    const onRegistrarPerfil = (e) => {
        e.preventDefault();
        // setMessage("");
        // setLoading(true);
        // form.current.validateAll();
        // if (checkBtn.current.context._errors.length === 0) {
        let perfilData = new PerfilModel();
        Object.assign(perfilData, {
            titulo: titulo,
            descricao: descricao,
            uuidempresa: uuidConta,
            permissoes: selected,
            status: 1
        })
        Perfilervice.postRegistrarPerfil(perfilData).then(
            (dt) => {
                if (dt.status === 200) {
                    // setLoading(false);
                    MySwal.fire({
                        title: <strong>Sucesso!</strong>,
                        html: <i>{dt.data.message}</i>,
                        icon: 'success'
                    });
                    handleClose();
                    carregarPerfis();
                } else {
                    // setLoading(false);
                    MySwal.fire({
                        title: <strong>Atencao!</strong>,
                        html: <i>{dt.data.message}</i>,
                        icon: 'warning'
                    })
                }

            }, (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setLoading(false);
                // setMessage(resMessage);
            })
        // } else {
        //     setLoading(false);
        // }
    }
    /*********************/
    /** Operações Modal  */
    /*********************/

    useEffect(() => {
        carregarPermissoes();
        const loggedInUser = tokenService.getUserData();
        if (loggedInUser) {
            setAuth(loggedInUser);
            if (loggedInUser.permissoes.find((pi) => {
                return pi.includes("super-admin");
            })) {
                // carregar o dropdown
                return setAdmin(true);
            } else {
                return setAdmin(false);
            }
        }
    }, []);

    /* Handle do titulo */
    const handleTitulo = (e) => {
        const tit = e.target.value;
        setTitulo(tit);
    }

    /* handle da descricao */
    const handleDescricao = (e) => {
        const desc = e.target.value;
        setDescricao(desc);
    }

    /* Carregar Permissoes */
    const carregarPermissoes = () => {

        Perfilervice.getListarPermissoes().then(
            (dt) => {
                if (dt.data.lista) {
                    let perm = dt.data.lista.map((item) => {
                        return {
                            value: item.id,
                            label: item.titulo
                        }
                    })
                    setListaPermissoes(perm);
                }
                // setListaPermissoes(dt.data.lista);
            },
            (error) => {
                const aviso = (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Erro', aviso, 'error');
            }
        );
    }

    const handleAddPermission = (e) => {

        setSelected(e);
        // setPermissaoPerfil((prevItens) => [
        //     ...prevItens,
        //     {
        //         idPermissao: e,
        //         idPerfil: perfil.id
        //     }
        // ])
        setPermissaoPerfil(selected);
    }

    const handleAddPermissionAtualizar = (e) => {
        // perfilAtualizar.selectedPermissoes = e
    }

    const setPermissoesPerfilAtualizar = (perfil) => {
        var list = perfil.selectedPermissoes.map((pr) => {
            return pr.id
        })
        // setListaPermissoes(list)
        perfilAtualizar.selectedPermissoes = list;

    }

    /** filtrar */
    const onFiltrar = (e) => {
        if (filtroValor !== "") {
            const filterBase = perfilListaBackup;
            const filtered = filterBase.filter((dt) => {
                if (dt[filtroTipo.value] !== null) {
                    return dt[filtroTipo.value].toLowerCase().includes(filtroValor.toLowerCase());
                }
            });
            setPerfilList(filtered);
        } else {
            setPerfilList(perfilListaBackup);
        }
    };

    return (
        <div className="container">
            <header className="jumbotron">
                <div className="row">
                    <div className="col-9">
                        <h3>Gerenciar Perfis</h3>
                    </div>
                    {
                        PermissionUtil.checkPermission("gerenciar-empresas", usuario) && (
                            <div className="col-3">
                                <button className="btn btn-primary btn-block" onClick={() => {
                                    handleShow()
                                }}>
                                    <span>
                                        Registrar Perfil
                                    </span>
                                </button>
                            </div>
                        )
                    }
                </div>
            </header>
            <Form>
                <div className="row">
                    <div className="col-5"></div>
                    <div className="col-3">
                        <div className="form-group">
                            <Select options={filtroList} onChange={(e) => setTipoFiltro(e)} />
                        </div>
                    </div>
                    <div className="col-3">
                        {
                            filtroTipo.value === 'dataVencimento' &&
                            <div className="form-group">
                                <DatePicker className="custom-datepicker" dateFormat="dd/MM/yyyy" selected={filtroValor} onChange={setValorFiltro} />
                            </div>
                        }
                        {
                            filtroTipo.value !== 'dataVencimento' &&
                            <div className="form-group">
                                <Input
                                    type="text"
                                    value={filtroValor}
                                    onChange={(e) => setValorFiltro(e.target.value)}
                                    placeholder="Enter filter value"
                                    className="form-control custom-input"
                                />
                            </div>
                        }

                    </div>
                    <div className="col-1 text-right mb-3" style={{ marginLeft: "-2%" }}>
                        <Button variant="primary" className="btn-custom" onClick={onFiltrar}>
                            Filtrar
                        </Button>
                    </div>
                    <div className="col-1"></div>
                </div>
            </Form>
            {
                isAdmin &&
                < div className="form-group">
                    <label htmlFor="tipo">Conta Relacionada</label>
                    <Dropdown>
                        <Dropdown.Toggle variant="success">
                            {Conta.NomeConta ? Conta.NomeConta : "Selecione"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                contaLista.map((conta, index) => {
                                    return (
                                        <>
                                            <Dropdown.Item key={index + conta.uuid} href="#" onClick={() => {
                                                setConta(conta)
                                                carregarPerfis(conta.uuid, conta.titulo)
                                            }} value={conta}>
                                                {conta.NomeConta}
                                            </Dropdown.Item>
                                        </>
                                    )
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            }
            <div className="row">
                <div className="col-12 text-center">
                    {
                        perfilLista.length === 0 &&
                        <h4 style={{ color: "#000000" }}>Nenhum Perfil encontrado.</h4>
                    }
                    {perfilLista.length > 0 &&
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Titulo</th>
                                    <th>Descricao</th>
                                    <th>Status</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    perfilLista.map((perfil, index) => {
                                        return (
                                            <>
                                                <tr key={index + 1}>
                                                    <td>{perfil.id}</td>
                                                    <td>{perfil.titulo}</td>
                                                    <td>{perfil.descricao}</td>
                                                    <td>{perfil.status === "1" ? 'Ativo' : perfil.status === "2" ? 'Bloqueado' : 'Inativo'}</td>
                                                    <td>
                                                        <Button ref={inputRef} variant="danger" onClick={() => {
                                                            handleDelete(perfil.uuidempresa, perfil.id)
                                                        }}>
                                                            <FaX />
                                                        </Button>
                                                        <Button style={buttonStyle} ref={inputRef} variant="success" onClick={() => {
                                                            setIdAtualizar(perfil.id);
                                                            setTituloAtualizar(perfil.titulo);
                                                            setDescricaoAtualizar(perfil.descricao);
                                                            setPermissoesAtualizar(perfil.selectedPermissoes);
                                                            setPerfilAtualizar(perfil);
                                                            setEditorState(EditorState.createWithContent(
                                                                ContentState.createFromBlockArray(
                                                                    convertFromHTML(perfil.descricao)
                                                                )
                                                            ))
                                                            setShowAtualizar(true)
                                                        }}>
                                                            <FaPencil />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            {/* Criar novo Perfil */}
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Registrar Perfil
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onRegistrarPerfil} ref={form}>
                        <div className="form-group">
                            <label htmlFor="titulo">Titulo</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="titulo"
                                value={titulo}
                                onChange={handleTitulo}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descrição</label>
                            {/* <Input
                                type="text"
                                className="form-control"
                                name="descricao"
                                value={descricao}
                                onChange={handleDescricao}
                                validations={[required]}
                            /> */}
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                        <div className="form-group">
                            <DualListBox
                                options={listaPermissoes}
                                selected={selected}
                                onChange={handleAddPermission}
                            />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onRegistrarPerfil}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Editar Perfil */}
            <Modal show={showAtualizar} onHide={handleCloseAtualizar} size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Editando Perfil - {tituloAtualizar}
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onRegistrarPerfil} ref={form}>
                        <div className="form-group">
                            <label htmlFor="titulo">Titulo</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="titulo"
                                value={tituloAtualizar}
                                onChange={handleTitulo}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descrição</label>
                            {/* <Input
                                type="text"
                                className="form-control"
                                name="descricao"
                                value={perfilAtualizar.descricao}
                                onChange={handleDescricaoAtualizar}
                                validations={[required]}
                            /> */}
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateAtualizarChange}
                            />
                        </div>
                        <div className="form-group">
                            <DualListBox
                                options={listaPermissoes}
                                selected={permissoesAtualizar}
                                onChange={handleAddPermissionAtualizar}
                            />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAtualizar}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onRegistrarPerfil}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ListarPerfis;