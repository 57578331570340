import axios from "./BaseService";

/**
 * listar importacoes ativas
 */
const getListarUm = (id) => {
    return axios.get(process.env.REACT_APP_API_URL + "importacao/listar/" + id);
}

/**
 * listar todas as importacoes
 */
const getListarTodos = (page, limit, filters, noPagination = false, shouldCount = true, totalRegistrosTela = 0) => {
    return axios.get(process.env.REACT_APP_API_URL + "importacao/listartodos", {
        params: { page, limit, filters: JSON.stringify(filters), noPagination, shouldCount, totalRegistrosTela }
    });
};


/**
 * Registrar nova importacao
 */
const postRegistrarImportacao = (importacaoData) => {
    return axios.post(process.env.REACT_APP_API_URL + "importacao/registrar", importacaoData);
}

/**
 * atualizar importacao
 */
const putAtualizarImportacao = (importacaoData) => {
    return axios.put(process.env.REACT_APP_API_URL + "importacao/atualizar", importacaoData);
}

/**
 * remover importacao
 */
const deleteRemoverImportacao = (importacaoData) => {
    return axios.delete(process.env.REACT_APP_API_URL + "importacao/remover/" + importacaoData);
}

const postUploadChunk = async (chunk) => {
    return axios.post(process.env.REACT_APP_API_URL + "importacao/registrar", chunk);
}

const updateCEPImportacao = async () => {
    return axios.put(process.env.REACT_APP_API_URL + "importacao/atualizarcep");
}

/**
 * Exportar importações (em CSV)
 */
const exportarImportacoes = (filters) => {
    // Certificar que `shouldCount` é `false` para a exportação
    return axios.get(process.env.REACT_APP_API_URL + "importacao/exportar", {
        params: { filters: JSON.stringify(filters), shouldCount: false }, // `shouldCount: false`
        responseType: 'blob' // Recebendo o arquivo como blob
    });
};


const ImportacaoService = {
    exportarImportacoes,
    postUploadChunk,
    getListarUm,
    getListarTodos,
    postRegistrarImportacao,
    putAtualizarImportacao,
    deleteRemoverImportacao,
    updateCEPImportacao
}

export default ImportacaoService;
