// import axios from "axios";
import axios from "./BaseService";
/** listar empresas */
const getEmpresas = (uuid) => {
  if(uuid){
    return axios.get(process.env.REACT_APP_API_URL + "empresa/listar/"+uuid);
  }else{
    return axios.get(process.env.REACT_APP_API_URL + "empresa/listar");
  }
};
/** registrar empresa */
const postRegistrarEmpresa = (empresaDados) => {
    return axios.post(process.env.REACT_APP_API_URL + "empresa/registrar", empresaDados);
}
/** atualizar empresa */
const putAtualizarEmpresa = (empresaDados) => {
    return axios.put(process.env.REACT_APP_API_URL + "empresa/atualizar", empresaDados);
}
/* remover empresa */
const deleteRemoverEmpresa = (uuidEmpresa) => {
    return axios.delete(process.env.REACT_APP_API_URL + "empresa/remover/"+uuidEmpresa);
}

/* recuperar contas auxiliares */
const postRecuperarAuxiliares = (uuidEmpresa) => {
  return axios.post(process.env.REACT_APP_API_URL + "bot/auxAccount", {uuid: uuidEmpresa});
}

const deleteRemoverAuxiliar = (dadosConta) => {
  return axios.delete(process.env.REACT_APP_API_URL + "contas/remover/"+dadosConta.uuidEmpresa+"/"+dadosConta.id)
}

const EmpresaService = {
  getEmpresas,
  postRegistrarEmpresa,
  putAtualizarEmpresa,
  deleteRemoverEmpresa,
  postRecuperarAuxiliares,
  deleteRemoverAuxiliar
}

export default EmpresaService;
