import React, { useState, useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import ImportacaoService from '../../services/importacaoservice';
import 'bootstrap/dist/css/bootstrap.min.css';
import tokenService from "../../services/token.service";
import LoadingBar from 'react-top-loading-bar';
import Progress from './Progress';

const ImportacaoTable = ({ columns, data, pageCount, pageIndex, setPageIndex, selectedColumns, handleColumnSelect, loading }) => {
    const {
        getTableProps,
        getTableBodyProps,
        rows,
        prepareRow,
        gotoPage,
        state: { pageIndex: tablePageIndex }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex },
            manualPagination: true,
            pageCount
        },
        usePagination
    );

    const handlePageClick = (number) => {
        if (!loading) {
            setPageIndex(number);
            gotoPage(number);
        }
    };

    const handleNextPage = () => {
        if (!loading && tablePageIndex < pageCount - 1) {
            setPageIndex(tablePageIndex + 1);
            gotoPage(tablePageIndex + 1);
        }
    };

    const handlePreviousPage = () => {
        if (!loading && tablePageIndex > 0) {
            setPageIndex(tablePageIndex - 1);
            gotoPage(tablePageIndex - 1);
        }
    };

    const maxVisibleButtons = 15;
    let startPage = Math.max(tablePageIndex - Math.floor(maxVisibleButtons / 2), 0);
    let endPage = Math.min(startPage + maxVisibleButtons - 1, pageCount - 1);

    if (endPage - startPage < maxVisibleButtons - 1) {
        startPage = Math.max(endPage - maxVisibleButtons + 1, 0);
    }

    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return (
        <>
            <table {...getTableProps()} className="table">
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.accessor}>
                                <input
                                    type="checkbox"
                                    id={column.accessor}
                                    checked={selectedColumns.includes(column.accessor)}
                                    onChange={() => handleColumnSelect(column.accessor)}
                                    disabled={loading}
                                />
                                <label htmlFor={column.accessor} style={{ color: 'black' }}>{column.header}</label>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr key={row.id}>
                                {row.cells.map(cell => (
                                    <td key={cell.column.id}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={loading || tablePageIndex === 0}>{'<'}</button>
                        {pageNumbers.map(number => (
                            <button
                                key={number}
                                onClick={() => handlePageClick(number)}
                                disabled={loading || number === tablePageIndex}
                                style={{ fontWeight: number === tablePageIndex ? 'bold' : 'normal' }}
                            >
                                {number + 1}
                            </button>
                        ))}
                        <button onClick={handleNextPage} disabled={loading || tablePageIndex === pageCount - 1 || pageCount === 0}>{'>'}</button>
                    </div>
                </div>
            </div>
        </>
    );
};

const ImportacaoPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage] = useState(20);
    const [filters, setFilters] = useState({});
    const [currentFilters, setCurrentFilters] = useState({});
    const [message, setMessage] = useState('');
    const [totalRecords, setTotalRecords] = useState(0); // Total de registros
    const [pageIndex, setPageIndex] = useState(0);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const user = tokenService.getUserData();

    const checkPermission = (requested) => {
        return user.permissoes.some((pi) => pi.includes(requested));
    };

    const columns = React.useMemo(
        () => [
            { header: 'CNPJ', accessor: 'cnpj' },
            { header: 'Razão Social', accessor: 'razaoSocial' },
            { header: 'Situação Cadastral', accessor: 'situacaoCadastral' },
            { header: 'CNAE', accessor: 'cnae' },
            { header: 'Tipo', accessor: 'tipo' },
            { header: 'Endereço', accessor: 'endereco' },
            { header: 'Cidade', accessor: 'cidade' },
            { header: 'Rua', accessor: 'rua' },
            { header: 'Complemento', accessor: 'complemento' },
            { header: 'Bairro', accessor: 'bairro' },
            { header: 'CEP', accessor: 'cep' },
            { header: 'Estado', accessor: 'estado' },
            { header: 'DDD', accessor: 'ddd' },
            { header: 'Telefone', accessor: 'telefone' },
            { header: 'DDD2', accessor: 'ddd2' },
            { header: 'Telefone2', accessor: 'telefone2' },
            { header: 'DDD3', accessor: 'ddd3' },
            { header: 'Telefone3', accessor: 'telefone3' },
            { header: 'Email', accessor: 'email' },
        ],
        []
    );

    useEffect(() => {
        setSelectedColumns(columns.map(column => column.accessor));
    }, [columns]);

    const fetchData = (page, appliedFilters, shouldCount, totalRegistrosTela) => {
        setLoading(true);
        setProgress(50);
        ImportacaoService.getListarTodos(page + 1, itemsPerPage, appliedFilters, false, shouldCount, totalRegistrosTela)
            .then(response => {
                if (response.data && response.data.lista) {
                    setData(response.data.lista);
                    // Atualiza o total de registros somente quando shouldCount é true
                    if (shouldCount) {
                        setTotalRecords(response.data.total);
                        setPageCount(Math.ceil(response.data.total / itemsPerPage));
                    }
                    setMessage('');
                } else {
                    setData([]);
                    if (shouldCount) {
                        setTotalRecords(0);
                        setPageCount(0);
                    }
                    setMessage('Nenhum dado retornado');
                }
                setLoading(false);
                setProgress(100);
            })
            .catch(() => {
                setData([]);
                if (shouldCount) {
                    setTotalRecords(0);
                    setPageCount(0);
                }
                setLoading(false);
                setProgress(0);
                setMessage('Erro ao buscar dados');
            });
    };

    const handleSearch = () => {
        // Filtrar somente campos preenchidos
        const cleanFilters = Object.keys(currentFilters).reduce((acc, key) => {
            if (currentFilters[key]) {
                acc[key] = currentFilters[key];
            }
            return acc;
        }, {});

        setFilters(cleanFilters);
        setPageIndex(0);
        fetchData(0, cleanFilters, true, 0); // Busca com count
    };

    const handlePageChange = (newPageIndex) => {
        setPageIndex(newPageIndex);
        fetchData(newPageIndex, filters, false, totalRecords); // Paginação sem count
    };

    const handleFilterChange = (filterId, event) => {
        setCurrentFilters(prevFilters => ({
            ...prevFilters,
            [filterId]: event.target.value
        }));
    };

    const handleColumnSelect = (column) => {
        setSelectedColumns(prevColumns => {
            if (prevColumns.includes(column)) {
                return prevColumns.filter(col => col !== column);
            } else {
                return [...prevColumns, column];
            }
        });
    };

    const handleExport = async () => {
        setLoading(true);

        // Remove os campos vazios diretamente no currentFilters
        const cleanFilters = Object.keys(currentFilters).reduce((acc, key) => {
            if (currentFilters[key]) {
                acc[key] = currentFilters[key];
            }
            return acc;
        }, {});

        try {
            const response = await ImportacaoService.exportarImportacoes(cleanFilters);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exportacao_importacoes.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Erro na exportação de dados:", error);
        }
    };

    return (
        <>
            <header className="jumbotron col-md-10 mr-auto ml-auto">
                <h3>Listar Importações</h3>
            </header>
            <div className="container">
                {checkPermission("cadastrar-importacao") && (
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button onClick={() => window.location.href = "/importacao/cadastrar"} className="btn btn-primary btn-md mb-3">
                                Cadastrar Importação
                            </button>
                        </div>
                    </div>
                )}
                {checkPermission("atualizar-cep-importacao") && (
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button onClick={ImportacaoService.updateCEPImportacao} className="btn btn-primary btn-md mb-3">
                                Atualizar CEP
                            </button>
                        </div>
                    </div>
                )}
                <div className="row">
                    {columns.map((column) => (
                        <div key={column.accessor} className="col-md-3">
                            <div className="form-group">
                                <label>{column.header}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name={column.accessor}
                                    value={currentFilters[column.accessor] || ''}
                                    onChange={event => handleFilterChange(column.accessor, event)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <LoadingBar color='#f11946' progress={progress} onLoaderFinished={() => setProgress(0)} />
                <div className="row mb-3">
                    <div className="col-md-6 offset-md-3 text-center">
                        <button className="btn btn-primary w-100" onClick={handleSearch} disabled={loading}>Buscar</button>
                    </div>
                </div>
                {message && <div className="alert alert-success">{message}</div>}
                {loading && <Progress />} {/* Mostra o progress enquanto carrega */}
            </div>

            {/* Alinhar no centro o texto do total de registros */}
            <div className="container-fluid text-center">
                {totalRecords > 0 && <p>Total de registros: {totalRecords}</p>}
                <ImportacaoTable
                    columns={columns}
                    data={data}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    setPageIndex={handlePageChange}
                    selectedColumns={selectedColumns}
                    handleColumnSelect={handleColumnSelect}
                    loading={loading}
                />
                <button className="btn btn-secondary w-100" onClick={handleExport} disabled={loading}>Exportar</button>
            </div>
        </>
    );
};

export default ImportacaoPage;
