import React, { useState, useEffect, useRef } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaPencil, FaPumpMedical, FaX } from "react-icons/fa6";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AvisosService from "../../services/avisos.service";
import CategoriaProdutoModel from "../../models/categoriaProduto.model"
import tokenService from "../../services/token.service";
import "../../style/general.css";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AvisosModel from "../../models/avisos.model";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import * as moment from 'moment';
import arrayUtils from "../../utils/arrayUtils";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

/** estilo dos botoes  */
const buttonStyle = {
    marginLeft: "15px"
};


const ListarAvisos = () => {

    /** UseState */
    const [listaAvisos, setListaAvisos] = useState([]);

    const [id, setId] = useState("");
    const [titulo, setTitulo] = useState("");
    const [descricao, setDescricao] = useState("");
    const [dataInicio, setDataInicio] = useState("");
    const [dataFim, setDataFim] = useState("");
    const [uid, setUid] = useState("");
    const [status, setStatus] = useState("");
    const [ImagemVideo, setImagemVideo] = useState("");

    const [idAtualizar, setIdAtualizar] = useState("");
    const [tituloAtualizar, setTituloAtualizar] = useState("");
    const [descricaoAtualizar, setDescricaoAtualizar] = useState("");
    const [dataInicioAtualizar, setDataInicioAtualizar] = useState("");
    const [dataFimAtualizar, setDataFimAtualizar] = useState("");
    const [uidAtualizar, setUidAtualizar] = useState("");
    const [statusAtualizar, setStatusAtualizar] = useState("");
    const [imagemVideoAtualizar, setImagemVideoAtualizar] = useState("");

    const [tipoConteudo, setTipoConteudo] = useState("nenhum");

    const [usuario, setUsuario] = useState("");
    const [isAdmin, setAdmin] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);
    const inputRef = useRef(null);
    const [previewSource, setPreviewSource] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showAtualizar, setShowAtualizar] = useState(false);
    const handleCloseAtualizar = () => setShowAtualizar(false);
    const handleShowAtualizar = () => setShowAtualizar(true);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const checkBtn = useRef();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const statusList = [
        { label: "Ativo", value: "A" },
        { label: "Inativo", value: "I" }
    ]


    /** Editor */
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setDescricao(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const onEditorStateAtualizarChange = (editorState) => {
        setEditorState(editorState)
        setDescricaoAtualizar(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };



    /** UseEffect para inicializar */
    useEffect(() => {
        let userData = tokenService.getUserData();
        setUsuario(userData);

        if (userData.permissoes.find((pi) => {
            return pi.includes("super-admin");
        })) {
            /** carregar Avisos */
            carregarAvisos();
            return setAdmin(true);
        } else {
            /** carregar Avisos */
            carregarAvisos();
            return setAdmin(false);
        }
    }, []);

    /** Carregar Avisos */
    const carregarAvisos = () => {
        setListaAvisos([]);
        AvisosService.getListarTodos().then(
            (response) => {
                let list = response.data.lista;
                if (list) {
                    setListaAvisos(list);
                }
            },
            (error) => {
                const aviso = (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Erro', aviso, 'error');
            });
    }

    /** Handlers dos campos */
    const onChangeTitulo = (event) => {
        const value = event.target.value;
        setTitulo(value);
    };
    const onChangeTituloAtualizar = (event) => {
        const value = event.target.value;
        setTituloAtualizar(value);
    };
    const onChangeImagemVideo = (event) => {
        const value = event.target.value;
        setImagemVideo(value);
    };
    const onChangeImagemVideoAtualizar = (event) => {
        const value = event.target.value;
        setImagemVideoAtualizar(value);
    };
    const onChangeDescricao = (event) => {
        const value = event.target.value;
        setDescricao(value);
    };
    const onChangeDescricaoAtualizar = (event) => {
        const value = event.target.value;
        setDescricaoAtualizar(value);
    };
    const onChangeDataInicio = (event) => {
        const value = event.target.value;
        setDataInicio(value);
    };
    const onChangeDataInicioAtualizar = (event) => {
        const value = event.target.value;
        setDataInicioAtualizar(value);
    };
    const onChangeDataFim = (event) => {
        const value = event.target.value;
        setDataFim(value);
    };
    const onChangeDataFimAtualizar = (event) => {
        const value = event.target.value;
        setDataFimAtualizar(value);
    };
    const onChangeStatus = (event) => {
        const value = event.target.value;
        setStatus(value);
    };
    const onChangeStatusAtualizar = (event) => {
        const value = event.target.value;
        setStatusAtualizar(value);
    };

    /** validador de Data ISO */
    const checkIsoDate = (dt) => {
        // var dateParse d = new Date(Date.parse(dt));
        // //dateParsed
        // //output: Wed Oct 05 2011 19:48:00 GMT+0500 (Pakistan Standard Time)
        // if (dateParsed.toISOString() == dt) {
        //     //Date is in ISO
        // } else if (dateParsed.toUTCString() == dt) {
        //     //DATE os om UTC Format
        // }
        // Verifica se a data está no formato ISO
        // if (dt instanceof Date && !isNaN(dt.getTime())) {
        //     if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(dt.toISOString())) {
        //         console.log("A data está no formato ISO:", dt.toISOString());
        //         return dt;
        //     } else {
        //         // Converte a data para o formato ISO
        //         console.log("Convertendo a data para o formato ISO:", dt.toISOString());
        //         return dt.toISOString;
        //     }
        // } else {
        //     return null
        //     console.log("Data inválida.");
        // }

        if (dt) {
            if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(dt)) {
                const utcDate = new Date(Date.UTC(
                    parseInt(dt.substring(0, 4)), // year
                    parseInt(dt.substring(5, 7)) - 1, // month (zero-based)
                    parseInt(dt.substring(8, 10)), // day
                    parseInt(dt.substring(11, 13)), // hour
                    parseInt(dt.substring(14, 16)), // minute
                    parseInt(dt.substring(17, 19)), // second
                    parseInt(dt.substring(20, 23)) // millisecond
                ));

                const formattedDate = utcDate.toLocaleDateString('en-CA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                });
                return formattedDate
            } else {
                const formattedDate = dt.toLocaleDateString('en-CA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                });
                return formattedDate
            }
        }
    }


    /** Registrar Aviso */
    const onRegistrarAviso = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            let avisoData = new AvisosModel();
            Object.assign(avisoData, {
                titulo: titulo,
                descricao: descricao,
                imagemVideo: ImagemVideo,
                tipoConteudo: tipoConteudo,
                dataInicio: checkIsoDate(dataInicio),//dataInicio.toISOString().slice(0, 19).replace('T', ' '),
                dataFim: checkIsoDate(dataFim),//dataFim.toISOString().slice(0, 19).replace('T', ' '),
                status: status.value
            });
            AvisosService.postRegistrarAviso(avisoData).then(
                (dt) => {
                    if (dt.status === 200) {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Sucesso!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'success'
                        });
                        setTitulo("");
                        setDescricao("");
                        // setDataInicio("");
                        // setDataFim("");
                        setStatus("")
                        carregarAvisos();
                        handleClose(true);
                    } else {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Atencao!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'warning'
                        })
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    }
    /** Atualizar Aviso */
    const onAtualizarAviso = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            let avisoData = new AvisosModel();
            Object.assign(avisoData, {
                id: id,
                titulo: tituloAtualizar,
                descricao: descricaoAtualizar,
                dataInicio: checkIsoDate(dataInicioAtualizar),//dataInicioAtualizar.toISOString().slice(0, 19).replace('T', ' '),
                dataFim: checkIsoDate(dataFimAtualizar),//dataFimAtualizar.toISOString().slice(0, 19).replace('T', ' '),
                status: statusAtualizar
            });
            AvisosService.putAtualizarAviso(avisoData).then(
                (dt) => {
                    if (dt.status === 200) {
                        setTituloAtualizar("");
                        setDescricaoAtualizar("");
                        setStatusAtualizar(null);
                        setId(null);
                        // setDataInicioAtualizar("");
                        // setDataFimAtualizar("");
                        setStatusAtualizar(null);
                        handleCloseAtualizar();
                        carregarAvisos();
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Sucesso!</strong>,
                            html: <i>Aviso atualizado com sucesso</i>,
                            icon: 'success'
                        })
                    } else {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Atencao!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'warning'
                        })
                    }
                })
        } else {
            setLoading(false);
            MySwal.fire({
                title: <strong>Atencao!</strong>,
                html: <i>Campos obrigatorios nao preenchidos</i>,
                icon: 'warning'
            })
        }
    }

    /** Remover Aviso */
    const handleDelete = (avisoData) => {
        MySwal.fire({
            title: "Voce tem certeza?",
            text: `Ao confirmar voce ira remover o aviso ${avisoData.titulo} do sistema`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar Remocao"
        }).then((result) => {
            if (result.isConfirmed) {
                AvisosService.deleteAviso({ id: avisoData.id }).then((r) => {
                    if (r.status === 200) {
                        MySwal.fire({
                            title: "Sucesso!",
                            text: "Aviso removido",
                            icon: "success"
                        });
                        carregarAvisos();
                    } else {
                        MySwal.fire({
                            title: "Atencao!",
                            text: `Ocorreu o seguinte erro: ` + r,
                            icon: "error"
                        });
                    }
                }, (error) => {
                    MySwal.fire({
                        title: "Atencao!",
                        text: "Ocorreu o seguinte erro:  " + error,
                        icon: "error"
                    });
                })
            }
        });
    }

    /** Formatar Data */
    const formatarData = (dt) => {
        if (dt) {
            let base = dt.substring(0, 10);
            const [year, month, day] = base.split('-');
            const formattedDate = [day, month, year].join('/');
            return formattedDate;
        } else {
            return null
        }
    }

    /** renderizacao */

    return (

        <div className="container">
            <header className="jumbotron">
                <div className="row">
                    <div className="col-9">
                        <h3>Gerenciar Avisos</h3>
                    </div>

                    <div className="col-3">
                        <button className="btn btn-primary btn-block" onClick={() => {
                            handleShow()
                        }}>
                            <span>
                                Adicionar Aviso
                            </span>
                        </button>
                    </div>

                </div>
            </header>
            <div className="row">
                <div className="col-12 text-center">
                    {
                        !listaAvisos || listaAvisos.length === 0 &&
                        <h4 style={{ color: "#000000" }}>Nenhum aviso encontrado.</h4>
                    }
                    {listaAvisos.length > 0 &&
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Titulo</th>
                                    <th>Data Inicio</th>
                                    <th>Data Fim</th>
                                    <th>Uid</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listaAvisos.map((aviso, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{aviso.titulo}</td>
                                                    <td>{formatarData(aviso.dataInicio)}</td>
                                                    <td>{formatarData(aviso.dataFim)}</td>
                                                    <td>{aviso.uid}</td>
                                                    <td>{aviso.status == 'A' ? "Ativo" : "Inativo"}</td>
                                                    <td>
                                                        <Button ref={inputRef} style={{ marginRight: 20 }} variant="success" onClick={() => {
                                                            setId(aviso.id);
                                                            setTituloAtualizar(aviso.titulo);
                                                            setEditorState(EditorState.createWithContent(
                                                                ContentState.createFromBlockArray(
                                                                    convertFromHTML(aviso.descricao)
                                                                )
                                                            ))
                                                            setDescricaoAtualizar(aviso.descricao);
                                                            setDataInicioAtualizar(aviso.dataInicio);
                                                            setDataFimAtualizar(aviso.dataFim);
                                                            setStatusAtualizar(aviso.status);
                                                            setUidAtualizar(aviso.uid);
                                                            // setTipoConteudo("nenhum");
                                                            handleShowAtualizar();
                                                        }}>
                                                            <FaPencil />
                                                        </Button>
                                                        <Button ref={inputRef} variant="danger" onClick={() => {
                                                            handleDelete(aviso)
                                                        }}>
                                                            <FaX />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            {/* Modal Novo Aviso */}
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Adicionar Aviso
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>

                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onRegistrarAviso} ref={form}>
                        <div className="form-group">
                            <label htmlFor="nome">Titulo</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nome"
                                value={titulo}
                                onChange={onChangeTitulo}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descrição</label>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="nome">Tipo de Midia</label>
                            <Row className="text-center mt-4 mb-4">
                                <Col>
                                    <Tabs
                                        defaultActiveKey="nenhum"
                                        id="justify-tab-example"
                                        className="mb-3"
                                        justify
                                        activeKey={tipoConteudo}
                                        onSelect={(k) => setTipoConteudo(k)}
                                    >
                                        <Tab eventKey="imagem" title="Imagem">
                                            <div className="form-group">
                                                {/* <label>Selecione a Imagem</label> */}
                                                <br></br>
                                                <input type="file" name="image"  />
                                            </div>
                                        </Tab>
                                        <Tab eventKey="video" title="video">
                                            <div className="form-group">
                                                <label className="text-left"> Video Link</label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="nome"
                                                    value={ImagemVideo}
                                                    onChange={onChangeImagemVideo}
                                                />
                                            </div>
                                        </Tab>
                                        <Tab eventKey="nenhum" title="Nenhum">
                                            <h6>
                                                Nenhum tipo de midia selecionado
                                                <br></br>
                                                (Lembre-se que ao selecionar você nao exibirá o conteúdo da descrição)
                                            </h6>
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>

                        </div>
                        <div className="form-group">
                            <label htmlFor="dataInicio">Data Inicio</label>
                            <br></br>
                            <DatePicker showIcon={true} locale="pt-BR" dateFormat="dd/MM/yyyy" selected={dataInicio} onChange={(date) => setDataInicio(date)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dataInicio">Data Fim</label>
                            <br></br>
                            <DatePicker showIcon={true} locale="pt-BR" dateFormat="dd/MM/yyyy" selected={dataFim} onChange={(date) => setDataFim(date)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <Select options={statusList} onChange={(e) => setStatus(e)} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onRegistrarAviso}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal atualizar */}
            <Modal show={showAtualizar} onHide={handleCloseAtualizar} size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Editar Aviso [{uidAtualizar}]
                        </h4>
                        <h6>
                            {tituloAtualizar}
                        </h6>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleCloseAtualizar}> X </Button>

                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onAtualizarAviso} ref={form}>
                        <div className="form-group">
                            <label htmlFor="nome">Titulo</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nome"
                                value={tituloAtualizar}
                                onChange={onChangeTituloAtualizar}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descrição</label>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateAtualizarChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dataInicio">Data Inicio</label>
                            <DatePicker showIcon={true} locale="pt-BR" dateFormat="dd/MM/yyyy" selected={moment.utc(moment(dataInicioAtualizar.substring(0, 10))).format()} onChange={(date) => setDataInicioAtualizar(date)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dataInicio">Data Fim</label>
                            <DatePicker showIcon={true} locale="pt-BR" utcOffset={0} dateFormat="dd/MM/yyyy" selected={moment.utc(moment(dataFimAtualizar.substring(0, 10))).format()} onChange={(date) => setDataFimAtualizar(date)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <Select options={statusList} onChange={(e) => setStatusAtualizar(e)} value={statusList.value} defaultValue={statusList[arrayUtils.getIndexArray(statusList, statusAtualizar)]} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAtualizar}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onAtualizarAviso}>
                        Atualizar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListarAvisos