import React, { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { FaPencil, FaX, FaEye, FaEyeLowVision, FaCircleInfo } from "react-icons/fa6";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EmpresaService from "../../services/empresa.service";
import "../../style/general.css";
import EmpresaModel from "../../models/empresa.model";
import PermissionUtil from "../../common/PermissionUtil";
import tokenService from "../../services/token.service";
import RoboService from "../../services/robo.service";
import { Tooltip } from 'react-tooltip'

const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const EmpresasListar = () => {

    /* userefs */
    const form = useRef();
    const checkBtn = useRef();

    const [EmpresasCadastradas, setListaEmpresas] = useState([]);
    const [authenticated, setauthenticated] = useState(null);
    const inputRef = useRef(null);
    const [show, setShow] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseCreate = () => setShowCreate(false);
    const handleShow = () => setShow(true);
    const handleShowCreate = () => setShowCreate(true);

    /* Auxiliares do form */
    const [nomeFantasia, setNomeFantasia] = useState("");
    const [usuarioInsta, setUsuarioInsta] = useState("");
    const [senhaInsta, setSenhaInsta] = useState("");
    const [uuidEmpresa, setUUIDEmpresa] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [usuario, setUsuario] = useState("");


    /** UseState do modal */
    const [userBot, setUserBot] = useState("");
    const [senhaBot, setSenhaBot] = useState("");
    const [userBotUpd, setUserBotUpd] = useState("");
    const [senhaBotUpd, setSenhaBotUpd] = useState("");
    const [requestList, setItemtoList] = useState([]);
    const [viewpassList, setViewPassList] = useState([]);
    const [usuarioInstagram, setUsuarioInstaModal] = useState(null);
    const [senhaInstagram, setSenhaInstaModal] = useState(null);
    const [nomeFantasiaCreate, setNomeFantasiaModal] = useState(null);

    const [listaAuxiliares, setAuxiliares] = useState([]);

    const [userAuxiliar, setUserAux] = useState("");
    const [senhaAuxiliar, setSenhaAux] = useState("");

    /** estilo dos botoes  */
    const buttonStyle = {
        marginLeft: "15px"
    };

    // checar se usuario esta logado
    useEffect(() => {
        const loadPage = () => {
            // load user
            const loggedInUser = localStorage.getItem("@elosworld:token");
            if (loggedInUser) {
                setauthenticated(loggedInUser);
            }
            // load user data
            let userData = tokenService.getUserData();
            setUsuario(userData);
            // listar empresas cadastradas
            carregarEmpresas();
        }
        loadPage();
    }, []);



    const carregarEmpresas = () => {
        setListaEmpresas([]);
        let userData = tokenService.getUserData();
        if(userData){
            EmpresaService.getEmpresas(userData.uuidEmpresa).then(
                (response) => {
                    setListaEmpresas(response.data.lista);
                },
                (error) => {
                    const _content =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                }
            );
        }else{
            EmpresaService.getEmpresas().then(
                (response) => {
                    setListaEmpresas(response.data.lista);
                },
                (error) => {
                    const _content =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    }

    /** Carregar contas auxiliares */
    const carregarAuxiliares = (uuid) => {
        setAuxiliares([]);
        EmpresaService.postRecuperarAuxiliares(uuid).then(
            (response) => {
                setAuxiliares(response.data.lista)
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Ocorreu um Erro',
                    _content,
                    'error');
            }
        )
    }

    /* confirma a remocao e remove a empresa */
    const handleDelete = (uuidEmpresa, nomeFantasia) => {
        MySwal.fire({
            title: "Voce tem certeza?",
            text: `Ao confirmar voce ira remover a Conta ${nomeFantasia} do sistema`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar Remocao"
        }).then((result) => {
            if (result.isConfirmed) {
                EmpresaService.deleteRemoverEmpresa(uuidEmpresa).then((r) => {
                    if (r.status === 200) {
                        MySwal.fire({
                            title: "Sucesso!",
                            text: "Conta removida",
                            icon: "success"
                        });
                        carregarEmpresas();
                    } else {
                        MySwal.fire({
                            title: "Atencao!",
                            text: `Ocorreu o seguinte erro: ` + r,
                            icon: "error"
                        });
                    }
                }, (error) => {
                    MySwal.fire({
                        title: "Atencao!",
                        text: "Ocorreu o seguinte erro:  " + error,
                        icon: "error"
                    });
                })
            }
        });
    }

    /** removre conta */
    const handleDeleteConta = (idConta) => {
        MySwal.fire({
            title: "Voce tem certeza?",
            text: `Ao confirmar voce ira remover a Conta do sistema`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar Remocao"
        }).then((result) => {
            if (result.isConfirmed) {
                let dataRem = {
                    uuidEmpresa: uuidEmpresa,
                    id: idConta
                }
                EmpresaService.deleteRemoverAuxiliar(dataRem).then((r) => {
                    MySwal.fire({
                        title: "Sucesso!",
                        text: "Conta removida",
                        icon: "success"
                    });
                    carregarAuxiliares(uuidEmpresa);

                }, (error) => {
                    MySwal.fire({
                        title: "Atencao!",
                        text: "Ocorreu o seguinte erro:  " + error,
                        icon: "error"
                    });
                })
            }
        });
    }

    /** salva as alteraocoes da empresa  */
    const state = {
        button: 1
    };

    const handleUpdateEmpresa = () => {
        let EmpresaData = new EmpresaModel();
        Object.assign(EmpresaData, {
            nomeFantasia: nomeFantasia,
            usuarioInstagram: usuarioInsta,
            senhaInstagram: senhaInsta,
            uuidEmpresa: uuidEmpresa
        })
        EmpresaService.putAtualizarEmpresa(EmpresaData).then(
            (dt) => {
                if (dt.status === 200) {
                    setLoading(false);
                    MySwal.fire({
                        title: <strong>Sucesso!</strong>,
                        html: <i>{dt.data.message}</i>,
                        icon: 'success'
                    });
                    setNomeFantasia("");
                    setUsuarioInsta("");
                    setSenhaInsta("");
                    carregarEmpresas();
                    handleClose();

                } else {
                    setLoading(false);
                    MySwal.fire({
                        title: <strong>Atencao!</strong>,
                        html: <i>{dt.data.message}</i>,
                        icon: 'warning'
                    })
                }

            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    // Funcoes da Modal
    /* clicar para registrar */
    const onRegistrarEmpresa = (e) => {
        
        if (e != undefined) {
            e.preventDefault();
        }
        if (state.button === "Executar") {
            setMessage("");
            setLoading(true);
            form.current.validateAll();
            if (checkBtn.current.context._errors.length === 0) {
                let EmpresaData = new EmpresaModel();
                Object.assign(EmpresaData, {
                    nomeFantasia: nomeFantasiaCreate,
                    usuarioInstagram: usuarioInstagram,
                    senhaInstagram: senhaInstagram,
                    contas: requestList,
                    status: 1
                })
                EmpresaService.postRegistrarEmpresa(EmpresaData).then(
                    (dt) => {
                        if (dt.status === 200) {
                            setLoading(false);
                            MySwal.fire({
                                title: <strong>Sucesso!</strong>,
                                html: <i>{dt.data.message}</i>,
                                icon: 'success'
                            });
                            setNomeFantasia(" ");
                            setUsuarioInsta(" ");
                            setSenhaInsta(" ");
                            setItemtoList([]);
                            handleCloseCreate();
                            carregarEmpresas();
                        } else {
                            setLoading(false);
                            MySwal.fire({
                                title: <strong>Atencao!</strong>,
                                html: <i>{dt.data.message}</i>,
                                icon: 'warning'
                            })
                        }

                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        setLoading(false);
                        setMessage(resMessage);
                    }
                );
            } else {
                setLoading(false);
            }
        }
        if (state.button === "Adicionar") {
            setItemtoList((prevItens) => [
                ...prevItens,
                {
                    user: userBot,
                    senha: senhaBot
                }
            ]);
            setUserBot("");
            setSenhaBot("");
        }
    }

    /* Quando houver alteracao no campo */
    const onChangeNomeFantasiaCreate = (e) => {
        const nomeFantasia = e.target.value;
        setNomeFantasiaModal(nomeFantasia);
    }
    const onChangeUsuarioInstaCreate = (e) => {
        const user = e.target.value;
        setUsuarioInstaModal(user);
    }
    const onChangeSenhaInstaCreate = (e) => {
        const senha = e.target.value;
        setSenhaInstaModal(senha);
    }
    const onChangeUserBotCreate = (e) => {
        const user = e.target.value;
        setUserBot(user);
    }
    const onChangeSenhaBotCreate = (e) => {
        const pass = e.target.value;
        setSenhaBot(pass);
    }
    const onChangeUserBotUpd = (e) => {
        const user = e.target.value;
        setUserBotUpd(user);
    }
    const onChangeSenhaBotUpd = (e) => {
        const pass = e.target.value;
        setSenhaBotUpd(pass);
    }

    const handleDeleteCreate = (e) => {
        requestList.splice(e, 1);
    }

    const updateHiddenPassList = (request) => {

        setViewPassList(
            viewpassList.filter(a =>
                a.instagramLogin !== request.user
            )
        );
    }


    const atualizarAuxiliaresConta = (e) => {

        e.preventDefault()

        /* montar o objeto para adicionar o auxiliar */
        let contaBot = {
            "uuidEmpresa": uuidEmpresa,
            "instagramLogin": userBotUpd,
            "instagramPass": senhaBotUpd,
            "qtdRun": null,
            "dataRun": null,
            "status": 'A'
        }
        RoboService.postContaBot(contaBot).then(
            (response) => {
                MySwal.fire({
                    title: <strong>Sucesso!</strong>,
                    html: <i>Conta Auxiliar Registrada com sucesso</i>,
                    icon: 'success'
                })
                carregarAuxiliares(uuidEmpresa);
                setUserBotUpd("");
                setSenhaBotUpd("");
            },
            (error) => {
                console.log(error)
            }
        );
    }

    // fim funcoes modal

    const onAtualizarEmpresa = () => {

    }
    /* Quando houver alteracao no campo */
    const onChangeNomeFantasia = (e) => {
        const nomeFantasia = e.target.value;
        setNomeFantasia(nomeFantasia);
    }
    const onChangeUsuarioInsta = (e) => {
        const user = e.target.value;
        setUsuarioInsta(user);
    }
    const onChangeSenhaInsta = (e) => {
        const senha = e.target.value;
        setSenhaInsta(senha);
    }

    if (!authenticated) {
        // Redirect
        <Navigate replace to="/login" />;
    } else {
        return (
            <div className="container">
                <header className="jumbotron">
                    <div className="row">
                        <div className="col-9">
                            <h3>Contas Registradas</h3>
                        </div>
                        {
                            PermissionUtil.checkPermission("gerenciar-empresas", usuario) && (
                                <div className="col-3">
                                    <button className="btn btn-primary btn-block" onClick={() => {
                                        handleShowCreate()
                                    }}>
                                        <span>
                                            Adicionar Conta
                                        </span>
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </header>
                <div className="row">
                    <div className="col-12 text-center">
                        {
                            EmpresasCadastradas.length === 0 &&
                            <h4 style={{ color: "#000000" }}>Nenhuma Conta encontrada.</h4>
                        }
                        {EmpresasCadastradas.length > 0 &&
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nome Conta</th>
                                        <th>Status</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        EmpresasCadastradas.map((empresa, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td>{empresa.id}</td>
                                                        <td>{empresa.nomeFantasia}</td>
                                                        <td>{empresa.status == `Ativo` || empresa.status == 1 ? "Ativo" : "Inativo"}</td>
                                                        <td>
                                                            <Button ref={inputRef} variant="danger" onClick={() => {
                                                                handleDelete(empresa.uuidEmpresa, empresa.nomeFantasia)
                                                            }}>
                                                                <FaX />
                                                            </Button>

                                                            <Button style={buttonStyle} ref={inputRef} variant="success" onClick={() => {
                                                                setNomeFantasia(empresa.nomeFantasia);
                                                                setUsuarioInsta(empresa.usuarioInstagram);
                                                                setUUIDEmpresa(empresa.uuidEmpresa);
                                                                carregarAuxiliares(empresa.uuidEmpresa);
                                                                handleShow()
                                                            }}>
                                                                <FaPencil />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        }
                    </div>
                </div>
                {/* Modal Editar */}
                <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
                    <Modal.Header>
                        <Modal.Title style={{ color: "#000000" }}>Editando Conta:
                            <p>
                                <h6>
                                    {nomeFantasia}
                                </h6>
                            </p>
                        </Modal.Title>
                        <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
                    </Modal.Header>
                    <Modal.Body
                    >
                        <Form onSubmit={onAtualizarEmpresa} ref={form}>
                            <div className="form-group">
                                <label htmlFor="nomeFantasia">Nome Conta</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    value={nomeFantasia}
                                    onChange={onChangeNomeFantasia}
                                    validations={[required]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="nomeFantasia">Usuario Instagram</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    value={usuarioInsta}
                                    onChange={onChangeUsuarioInsta}
                                    validations={[required]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Senha Instagram</label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={senhaInsta}
                                    onChange={onChangeSenhaInsta}
                                    validations={[required]}
                                />
                            </div>
                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            <div className="row">
                                <h4> Contas Auxiliares </h4>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="nomeFantasia">Usuario Instagram </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={userBotUpd}
                                            onChange={onChangeUserBotUpd}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="password">Senha Instagram</label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={senhaBotUpd}
                                            onChange={onChangeSenhaBotUpd}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3" style={{ marginTop: '42px' }}>
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block" onClick={atualizarAuxiliaresConta}>
                                            <span>Adicionar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </Form>
                        {
                            listaAuxiliares.length > 0 &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mt-3 ml-4">
                                        <div className="row">
                                            <h4> Contas Auxiliares </h4>
                                        </div>
                                        <div className="col-12">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th> Usuario</th>
                                                        <th> Senha</th>
                                                        <th> Qtd Uso.</th>
                                                        <th> Ult. Uso</th>
                                                        <th> Status</th>
                                                        <th>Ação</th>
                                                    </tr>
                                                    {
                                                        listaAuxiliares.map((request, index) => {

                                                            let hidepass = '';
                                                            for (let index = 0; index < request.instagramPass.length; index++) {
                                                                hidepass += '*';

                                                            }

                                                            return (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td> {request.instagramLogin}</td>
                                                                        <td>
                                                                            {
                                                                                (viewpassList.filter((x) => { return x.name == request.instagramLogin })).length > 0 ?
                                                                                    <>
                                                                                        {request.senha}
                                                                                        <Button ref={inputRef} style={{ marginLeft: 10 }} onClick={() => {
                                                                                            setViewPassList(
                                                                                                viewpassList.filter(a =>
                                                                                                    a.name !== request.instagramLogin
                                                                                                )
                                                                                            );
                                                                                        }}>
                                                                                            <FaEyeLowVision />
                                                                                        </Button>
                                                                                    </>
                                                                                    :

                                                                                    <>
                                                                                        {hidepass}
                                                                                        <Button ref={inputRef} style={{ marginLeft: 10 }} onClick={(index) => {
                                                                                            setViewPassList([
                                                                                                ...viewpassList,
                                                                                                { id: index, name: request.instagramLogin }
                                                                                            ]);
                                                                                        }}>
                                                                                            <FaEye />
                                                                                        </Button>
                                                                                    </>

                                                                            }
                                                                        </td >
                                                                        <td>
                                                                            {request.qtdRun}
                                                                        </td>
                                                                        <td>
                                                                            {request.dataRun}
                                                                        </td>
                                                                        <td>
                                                                            {request.status == 'CB' ? 'Bloqueado (checkpoint)' : request.status == 'CP' ? 'Bloqueado (checkpoint)' : request.status == 'A' ? 'Ativo' : request.status}
                                                                            {
                                                                                request.status == 'CB' || request.status == 'CP' &&
                                                                                <FaCircleInfo data-tooltip-id="my-tooltip" data-tooltip-content="Isso significa que o usuário está apresentando algum erro na autenticação recomendamos tentar fazer o login pelo navegador" />
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <Button ref={inputRef} variant="danger" onClick={() => {
                                                                                handleDeleteConta(request.id)
                                                                            }}>
                                                                                <FaX />
                                                                            </Button>

                                                                        </td>
                                                                    </tr >
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </Table>
                                            <Tooltip id="my-tooltip" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fechar
                        </Button>
                        <Button variant="primary" onClick={handleUpdateEmpresa}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Modal Criar */}
                <Modal show={showCreate} onHide={handleClose} size="lg" backdrop="static">
                    <Modal.Header>
                        <Modal.Title>
                            <h3>
                                Criar nova Conta
                            </h3>
                        </Modal.Title>
                        <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={onRegistrarEmpresa} ref={form}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="nomeFantasia">Nome Conta</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={nomeFantasiaCreate}
                                            onChange={onChangeNomeFantasiaCreate}
                                            validations={[required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nomeFantasia">Usuario Instagram Principal</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={usuarioInstagram}
                                            onChange={onChangeUsuarioInstaCreate}
                                            validations={[required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Senha Instagram</label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={senhaInstagram}
                                            onChange={onChangeSenhaInstaCreate}
                                            validations={[required]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <h2> Contas auxiliares: </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="nomeFantasia">Usuario Instagram </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={userBot}
                                            onChange={onChangeUserBotCreate}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="password">Senha Instagram</label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={senhaBot}
                                            onChange={onChangeSenhaBotCreate}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3" style={{ marginTop: '42px' }}>
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block" disabled={loading} onClick={() => { state.button = "Adicionar" }}>
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Adicionar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <hr></hr>
                                    {
                                        requestList.length > 0 &&
                                        <div className="form-group mt-3">
                                            <div className="row">
                                                <h3> Lista de processamento </h3>
                                            </div>
                                            <div className="col-12">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>Usuario</th>
                                                            <th>Senha</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            requestList.map((request, index) => {
                                                                let hidepass = '';
                                                                for (let index = 0; index < request.senha.length; index++) {
                                                                    hidepass += '*';

                                                                }
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td> {request.user}</td>
                                                                            <td>
                                                                                {
                                                                                    Object.keys(viewpassList).map((key) => {
                                                                                        return <div>
                                                                                            <span>"Id is:"{key}</span>
                                                                                            <span>"Name is:"{viewpassList[key].name}</span>
                                                                                        </div>
                                                                                    })

                                                                                }
                                                                                {
                                                                                    (viewpassList.filter((x) => { return x.name == request.user })).length > 0 ?
                                                                                        <>
                                                                                            {request.senha}
                                                                                            <Button ref={inputRef} style={{ marginLeft: 10 }} onClick={() => {
                                                                                                updateHiddenPassList(request)
                                                                                            }}>
                                                                                                <FaEyeLowVision />
                                                                                            </Button>
                                                                                        </>


                                                                                        :

                                                                                        <>
                                                                                            {hidepass}
                                                                                            <Button ref={inputRef} style={{ marginLeft: 10 }} onClick={(index) => {
                                                                                                setViewPassList([
                                                                                                    ...viewpassList,
                                                                                                    { id: index, name: request.user }
                                                                                                ]);
                                                                                            }}>
                                                                                                <FaEye />
                                                                                            </Button>
                                                                                        </>

                                                                                }
                                                                            </td >
                                                                            <td>
                                                                                <Button ref={inputRef} variant="danger" onClick={() => {
                                                                                    handleDelete(index)
                                                                                }}>
                                                                                    <FaX />
                                                                                </Button>

                                                                            </td>
                                                                        </tr >
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block" onClick={() => { state.button = "Executar"; }}>
                                            <span>Cadastrar</span>
                                        </button>
                                    </div>
                                </div>
                                {message && (
                                    <div className="form-group">
                                        <div className="alert alert-danger" role="alert">
                                            {message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </div>
                        </Form >
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseCreate}>
                            Fechar
                        </Button>
                        <Button variant="primary" onClick={() => { state.button = "Executar"; onRegistrarEmpresa() }}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default EmpresasListar;