class AvisosModel {
    constructor(id, titulo, descricao, dataInicio, dataFim, uid, status) {
        this.id = id;
        this.titulo = titulo;        
        this.descricao = descricao;
        this.dataInicio = dataInicio;
        this.dataFim = dataFim;
        this.uid = uid
        this.status = status;
    }
}

export default AvisosModel;


