import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import "../../style/general.css";
import ProdutoService from "../../services/produtos.service";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const BoardUser = () => {
  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("@elosworld:token");
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  const [content, setContent] = useState("");
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    ProdutoService.getListarAtivos().then((response) => {
      setProductList(response.data.lista);
    })
  }, []);


  if (!authenticated) {
    // Redirect
    <Navigate replace to="/login" />;
  } else {
    return (
      <div>
        <header className="jumbotron">
          <h3>Produtos Disponíveis</h3>
        </header>
        <div className="row">
          <div class="col-md-1"></div>
          {
            productList.map((product, index) => {
              return (
                <>
                  <div className="col-md-2">
                    <Card style={{ width: '18rem' }}>
                      {/* <Card.Img variant="top" src={product.imagem} /> */}
                      <Card.Body>
                        <Card.Title>{product.titulo}</Card.Title>
                        <Card.Text>
                          {product.descricao}
                        </Card.Text>
                        {
                          product.tipo == "Link" &&
                          <Button variant="primary" href={product.endereco}>Acessar</Button>
                        }
                        {
                          product.tipo == "Executavel" &&
                          <Button variant="primary" href={product.endereco}>Download</Button>
                        }
                      </Card.Body>
                    </Card>
                  </div >
                </>
              )
            })
          }


        </div>
      </div>
    );
  }
}

export default BoardUser;
