class EmpresaModel {
    constructor(id, uuidEmpresa, nomeFantasia, usuarioInstagram, senhaInstagram, contas, status) {
        this.id = id;
        this.uuidEmpresa = uuidEmpresa;
        this.nomeFantasia = nomeFantasia;
        this.usuarioInstagram = usuarioInstagram;
        this.senhaInstagram = senhaInstagram;
        this.contas = contas;
        this.status = status;
    }
}

export default EmpresaModel