import axios from "./BaseService";

/**
 * listar produtos ativos
 */
const getListarAtivos = () => {
    return axios.get(process.env.REACT_APP_API_URL+"produto/listar")
}

/**
 * listar  todos os produtos
 */
const getListarTodos = () => {
    return axios.get(process.env.REACT_APP_API_URL+"produto/listar/todos");
}

/**
 * listar  categorias produtos
 */
const getListarCategorias = () => {
    return axios.get(process.env.REACT_APP_API_URL+"categoriaProduto/listar");
}

/**
 * Registrar novo produto
 */
const postRegistrarProduto = (produtoData, imagem) => {
    return axios.post(process.env.REACT_APP_API_URL + "produto/registrar", produtoData);
};
const postRegistrarCategoriaProduto = (produtoData, imagem) => {
    return axios.post(process.env.REACT_APP_API_URL + "categoriaProduto/registrar", produtoData);
};

const postRegistrarImagemProduto = (imagem) => {
    const formData = new FormData();
    formData.append('file', imagem);
    return axios.post(process.env.REACT_APP_API_URL + "produto/registrar/imagem", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * atualizar produto
 */
const putAtualizarProduto = (produtoData) => {
    return axios.put(process.env.REACT_APP_API_URL+"produto/atualizar", produtoData);
}

/**
 * Atualizar Categoria Produto
 */
const putAtualizarCategoriaProduto = (produtoData) => {
    return axios.put(process.env.REACT_APP_API_URL+"categoriaProduto/atualizar", produtoData);
}

/**
 * remover produto
 */
const deleteRemoverProduto = (produtoData) => {
    return axios.delete(process.env.REACT_APP_API_URL+"produto/remover/"+produtoData);
}

/**
 * remover categoria produto
 */
const deleteCategoriaProduto = (produtoData) => {
    return axios.delete(process.env.REACT_APP_API_URL+"categoriaProduto/remover", {data: produtoData});
}

const ProdutoService = {
    getListarAtivos,
    getListarTodos,
    getListarCategorias,
    postRegistrarProduto,
    postRegistrarImagemProduto,
    postRegistrarCategoriaProduto,
    putAtualizarProduto,
    putAtualizarCategoriaProduto,
    deleteRemoverProduto,
    deleteCategoriaProduto
}

export default ProdutoService;