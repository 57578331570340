// import axios from "axios";
import axios from "./BaseService";
import tokenService from "./token.service";

/** listar respostas do BOT */
const getRespostas = (idEmpresa) => {
    return axios.get(process.env.REACT_APP_API_URL + `bot/respostas/${idEmpresa}`)
}

/** realizar request */
const postGerarRequestInstagram = async (request) => {
    let userData = tokenService.getUserData();
    let contaData = await RoboService.getConta(userData.uuidEmpresa);
    let requestBot = {}
    
    /** separar os tipos de request  */
    let instaRequest = request.searchList.filter((x) => { if (x.robo === "Instagram") { return x } });
    let tiktokRequest = request.searchList.filter((x) => { if (x.robo === "TikTok") { return x } });

    if (contaData.data.lista.length > 0) {
        requestBot = {
            "user": contaData.data.lista[0].instagramLogin,
            "userUuid": userData.uuid,
            "pass": contaData.data.lista[0].instagramPass,
            "uuidEmpresa": userData.uuidEmpresa,
            "searchItens": request.searchList
        }
    } else {
        requestBot = {
            "user": userData.usuarioInstagram,
            "userUuid": userData.uuid,
            "pass": userData.senhaInstagram,
            "uuidEmpresa": userData.uuidEmpresa,
            "searchList": request.searchList
        }
    }
    console.log("run instagram bot");
    // return axios.post(process.env.REACT_APP_ROBO_URL + `run`, requestBot);
    // return axios.post(process.env.REACT_APP_API_URL + `bot/instagram/perfil`, requestBot);
    return axios.post(process.env.REACT_APP_API_URL + `bot/instagram/flux`, requestBot);
}

const postGerarRequestTiktok = async (request) => {
    let userData = tokenService.getUserData();
    let requestBot = {}

    requestBot = {
        "userUuid": userData.uuid,
        "uuidEmpresa": userData.uuidEmpresa,
        "searchItens": request.searchList,
        "requisitante":userData.uuid
    }
    console.log("run tiktok bot");

    return axios.post(process.env.REACT_APP_API_URL + `bot/tiktok/executar`, requestBot);

}

const getConta = (request) => {
    return axios.get(process.env.REACT_APP_API_URL + `bot/botAccount`, { uuid: request });
}

const postContaBot = (request) => {
    return axios.post(process.env.REACT_APP_API_URL + `contas/registrar`, request);
}

const getLogsProcessamento = (usuario) => {
    return axios.get(process.env.REACT_APP_API_URL + `bot/log/${usuario}`);
}


const RoboService = {
    getRespostas,
    getConta,
    postGerarRequestInstagram,
    postGerarRequestTiktok,
    getLogsProcessamento,
    postContaBot
}

export default RoboService;