const checarAvisosVistos = (novosAvisos) => {
    if(novosAvisos) {
        /** vamos recuperar o localStorage de lidos */
        let listaAtual = localStorage.getItem("@elosworld:ListaAvisosLidos") !== null ? JSON.parse(localStorage.getItem("@elosworld:ListaAvisosLidos")) : [];
        return novosAvisos.filter((df) => !listaAtual.includes(df.uid));
    } else {
        return [];
    }
}

const avisosUtils = {
    checarAvisosVistos
}

export default avisosUtils