import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FaPencil, FaX, FaEye, FaEyeLowVision } from "react-icons/fa6";
import CheckButton from "react-validation/build/button";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EmpresaService from "../../services/empresa.service";
import EmpresaModel from "../../models/empresa.model";

const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const CadastrarEmpresa = () => {
    const inputRef = useRef(null);

    const state = {
        button: 1
    };

    const form = useRef();
    const checkBtn = useRef();

    /* useState para armazenar as informacoes */
    const [nomeFantasia, setNomeFantasia] = useState(null);
    const [usuarioInstagram, setUsuarioInsta] = useState(null);
    const [senhaInstagram, setSenhaInsta] = useState(null);
    const [userBot, setUserBot] = useState("");
    const [senhaBot, setSenhaBot] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [requestList, setItemtoList] = useState([]);
    const [viewpassList, setViewPassList] = useState([]);

    const [userAuxiliar, setUserAux] = useState("");
    const [senhaAuxiliar, setSenhaAux] = useState("");

    /* clicar para registrar */
    const onRegistrarEmpresa = (e) => {
        e.preventDefault();
        if (state.button === "Executar") {
            setMessage("");
            setLoading(true);
            form.current.validateAll();
            if (checkBtn.current.context._errors.length === 0) {
                let EmpresaData = new EmpresaModel();
                Object.assign(EmpresaData, {
                    nomeFantasia: nomeFantasia,
                    usuarioInstagram: usuarioInstagram,
                    senhaInstagram: senhaInstagram,
                    contas: requestList,
                    status: 1
                })
                EmpresaService.postRegistrarEmpresa(EmpresaData).then(
                    (dt) => {
                        if (dt.status === 200) {
                            setLoading(false);
                            MySwal.fire({
                                title: <strong>Sucesso!</strong>,
                                html: <i>{dt.data.message}</i>,
                                icon: 'success'
                            });
                            setNomeFantasia(" ");
                            setUsuarioInsta(" ");
                            setSenhaInsta(" ");
                            setItemtoList([]);
                            // poe no localhost
                            //   let udt = tokenService.getUserData();
                            //   directCheck(udt.permissoes);            
                            //   window.location.reload();            
                        } else {
                            setLoading(false);
                            MySwal.fire({
                                title: <strong>Atencao!</strong>,
                                html: <i>{dt.data.message}</i>,
                                icon: 'warning'
                            })
                        }

                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        setLoading(false);
                        setMessage(resMessage);
                    }
                );
            } else {
                setLoading(false);
            }
        }
        if (state.button === "Adicionar") {
            setItemtoList((prevItens) => [
                ...prevItens,
                {
                    user: userBot,
                    senha: senhaBot
                }
            ]);
            setUserBot("");
            setSenhaBot("");
        }
    }

    /* Quando houver alteracao no campo */
    const onChangeNomeFantasia = (e) => {
        const nomeFantasia = e.target.value;
        setNomeFantasia(nomeFantasia);
    }
    const onChangeUsuarioInsta = (e) => {
        const user = e.target.value;
        setUsuarioInsta(user);
    }
    const onChangeSenhaInsta = (e) => {
        const senha = e.target.value;
        setSenhaInsta(senha);
    }
    const onChangeUserBot = (e) => {
        const user = e.target.value;
        setUserBot(user);
    }
    const onChangeSenhaBot = (e) => {
        const pass = e.target.value;
        setSenhaBot(pass);
    }

    const handleDelete = (e) => {
        requestList.splice(e, 1);
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h1>
                        Cadastrar nova conta
                    </h1>
                </div>
            </div>
            <Form onSubmit={onRegistrarEmpresa} ref={form}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="nomeFantasia">Nome Conta</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={nomeFantasia}
                                onChange={onChangeNomeFantasia}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="nomeFantasia">Usuario Instagram Principal</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={usuarioInstagram}
                                onChange={onChangeUsuarioInsta}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Senha Instagram</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={senhaInstagram}
                                onChange={onChangeSenhaInsta}
                                validations={[required]}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <h2> Contas auxiliares: </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="nomeFantasia">Usuario Instagram </label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={userBot}
                                onChange={onChangeUserBot}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="password">Senha Instagram</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={senhaBot}
                                onChange={onChangeSenhaBot}
                            />
                        </div>
                    </div>
                    <div className="col-md-3" style={{ marginTop: '42px' }}>
                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading} onClick={() => { state.button = "Adicionar" }}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Adicionar</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <hr></hr>
                        {
                            requestList.length > 0 &&
                            <div className="form-group mt-3">
                                <div className="row">
                                    <h3> Lista de processamento </h3>
                                </div>
                                <div className="col-12">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Usuario</th>
                                                <th>Senha</th>
                                                <th>Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                requestList.map((request, index) => {
                                                    let hidepass = '';
                                                    for (let index = 0; index < request.senha.length; index++) {
                                                        hidepass += '*';
                                                        
                                                    }
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td> {request.user}</td>
                                                                <td>
                                                                    {
                                                                        (viewpassList.filter((x) => { return x.name == request.user })).length > 0 ?
                                                                            <>
                                                                                {request.senha}
                                                                                <Button ref={inputRef} style={{ marginLeft: 10 }} onClick={() => {
                                                                                     setViewPassList(
                                                                                        viewpassList.filter(a =>
                                                                                          a.name !== request.user
                                                                                        )
                                                                                      );
                                                                                }}>
                                                                                    <FaEyeLowVision />
                                                                                </Button>
                                                                            </>
                                                                            :
                                                                            
                                                                            <>          
                                                                                {hidepass}                                                               
                                                                                <Button ref={inputRef} style={{ marginLeft: 10 }} onClick={(index) => {                                                                                    
                                                                                    setViewPassList([
                                                                                        ...viewpassList,
                                                                                        { id: index, name: request.user }
                                                                                      ]);
                                                                                }}>
                                                                                    <FaEye />
                                                                                </Button>
                                                                            </>

                                                                    }
                                                                </td >
                                                                <td>
                                                                    <Button ref={inputRef} variant="danger" onClick={() => {
                                                                        handleDelete(index)
                                                                    }}>
                                                                        <FaX />
                                                                    </Button>

                                                                </td>
                                                            </tr >
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        }
                    </div>
                </div >
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading} onClick={() => { state.button = "Executar" }}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Cadastrar</span>
                            </button>
                        </div>
                    </div>
                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </div>
            </Form >
        </>
    );
}

export default CadastrarEmpresa;