import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Player } from '@lottiefiles/react-lottie-player';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import AuthService from "../services/auth.service";
import tokenService from "../services/token.service";
import DatePicker from "react-datepicker";
import UsuarioModel from "../models/usuario.model";
import "../style/login.css";

const MySwal = withReactContent(Swal)

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">
        Campos obrigatórios
      </div>
    );
  }
};

const Login = () => {

  const form = useRef();
  const form2 = useRef();
  const checkBtn = useRef();
  const checkBtn2 = useRef();

  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [emailCadastro, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [perfil, setPerfil] = useState({ value: 1000, label: "Cliente" });
  const [dataVencimento, setDataVencimento] = useState(new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)));
  const [nomeCadastro, setNome] = useState("");
  const [telefoneCadastro, setTelefone] = useState("");


  const [showCadastro, setAbrirCadastro] = useState(false);
  const [showRedefinirSenha, setRedifinirSenha] = useState(false);
  const handleClose = () => setAbrirCadastro(false);
  const handleCloseRedifinirSenha = () => setRedifinirSenha(false);
  const navigate = useNavigate();

  const onChangeemail = (e) => {
    const email = e.target.value;
    setemail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeNome = (e) => {
    const nome = e.target.value;
    setNome(nome);
  }

  const onChangeTelefone = (e) => {
    const telefone = e.target.value;
    setTelefone(telefone);
  }

  const directCheck = (permissoes) => {

    // check para dashboard
    if (permissoes.find((dt) => { return dt.includes("admin-dash") })) {
      navigate("/adminDashboard");
    }
    // Mod dashboard
    else if (permissoes.find((dt) => { return dt.includes("mod-dash") })) {
      navigate("/modDashboard");
    }
    // dashboard
    else if (permissoes.find((dt) => { return dt.includes("default-dash") })) {
      navigate("/dashboard");
    }
    else {
      navigate("/profile");
    }
  }

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(email, password).then(
        (dt) => {
          if (dt.status === 200) {
            // poe no localhost
            let udt = tokenService.getUserData();
            tokenService.clearAvisosLidos();
            directCheck(udt.permissoes);
            window.location.reload();
          } else {
            setLoading(false);
            MySwal.fire({
              title: <strong>Atencao!</strong>,
              html: <i>{dt.data.message}</i>,
              icon: 'warning'
            })
          }

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  /* Quando houver alteracao no campo */
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  }
  const onChangeSenha = (e) => {
    const senha = e.target.value;
    setSenha(senha);
  }
  const onChangeSenhaAtualizar = (e) => {
    const senha = e.target.value;
    setSenha(senha);
  }

  const onRegistrarUsuario = (e) => {

    e.preventDefault();
    setMessage("");
    setLoading(true);
    form2.current.validateAll();
    if (checkBtn2.current.context._errors.length === 0) {
      let userData = new UsuarioModel();
      Object.assign(userData, {
        email: emailCadastro,
        password: senha,
        idPerfil: 1001,
        dataVencimento: dataVencimento,
        nome: nomeCadastro,
        telefone: telefoneCadastro,
        status: 1
      })
      AuthService.postCadastrarUsuarioOut(userData).then(
        (dt) => {
          if (dt.status === 200) {
            setLoading(false);
            MySwal.fire({
              title: <strong>Sucesso!</strong>,
              html: <i>{dt.data.message}</i>,
              icon: 'success'
            });
            handleClose();
          } else {
            setLoading(false);
            MySwal.fire({
              title: <strong>Atencao!</strong>,
              html: <i>{dt.data.message}</i>,
              icon: 'warning'
            })
          }

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleResetPasswordRequest = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form2.current.validateAll();

    if (checkBtn2.current.context._errors.length === 0) {
      AuthService.requestPasswordReset(emailCadastro).then(
        (response) => {
          setLoading(false);
          MySwal.fire({
            title: <strong>Verifique seu e-mail!</strong>,
            html: <i>Um link para redefinir sua senha foi enviado para {emailCadastro}.</i>,
            icon: 'success'
          });
          handleCloseRedifinirSenha(); // Fechar o modal após o sucesso
        },
        (error) => {
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };


  return (
    <div className="col-md-12 mt-4 login-box">
      <style>{'body { background-color: #192028;}, * { color: #fff; }'}</style>
      <div className="card card-container">

        <Player
          src='https://lottie.host/b3ffb8c7-b5f1-4e47-b81d-d02ed033fd11/JSSKr1VDL4.json'
          className="player"
          loop
          autoplay
        /><h2>ElosWorld</h2>

        <Form onSubmit={handleLogin} ref={form}>
          <div className="form-group">
            <label htmlFor="email">Informe o e-mail</label>
            <Input
              type="text"
              className="form-control"
              name="email"
              placeholder="Digite aqui o e-mail"
              value={email}
              onChange={onChangeemail}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Informe a senha</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              placeholder="Digite aqui a senha"
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading}>
              <span>Acessar</span>
            </button>
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading} onClick={() => {
              setAbrirCadastro(true);
            }}>
              <span>Cadastre-se</span>
            </button>
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-block" type="button" disabled={loading} onClick={() => {
              setRedifinirSenha(true);
            }}>
              <span>Redefinir Senha</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>

      {/* Criar novo Usuario */}
      <Modal show={showCadastro} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title style={{ color: "#000000" }}>
            <h4>
              Cadastrar Novo Usuário
            </h4>
          </Modal.Title>
          <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onRegistrarUsuario} ref={form2}>
            <div className="form-group">
              <label htmlFor="nome">Nome</label>
              <Input
                type="text"
                className="form-control"
                name="nome"
                value={nomeCadastro}
                onChange={onChangeNome}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <label htmlFor="telefone">Telefone</label>
              <Input
                type="text"
                className="form-control"
                name="telefone"
                value={telefoneCadastro}
                onChange={onChangeTelefone}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                value={emailCadastro}
                onChange={onChangeEmail}
                validations={[required]}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Senha</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={senha}
                onChange={onChangeSenha}
                validations={[required]}
              />
            </div>
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn2} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onRegistrarUsuario}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Redefinir Senha */}
      <Modal show={showRedefinirSenha} onHide={handleCloseRedifinirSenha}>
        <Modal.Header>
          <Modal.Title style={{ color: "#000000" }}>
            <h4>Redefinir Senha</h4>
          </Modal.Title>
          <Button className="col-md-2 mdl-close-btn" onClick={handleCloseRedifinirSenha}> X </Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleResetPasswordRequest} ref={form2}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                placeholder="Digite o e-mail para redefinir a senha"
                value={emailCadastro}
                onChange={onChangeEmail}
                validations={[required]}
              />
            </div>
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn2} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRedifinirSenha}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleResetPasswordRequest}>
            Enviar Email
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  );
};

export default Login;
