
import { jwtDecode } from "jwt-decode";

const getUserData = () => {
    var userdata = localStorage.getItem("@elosworld:token") ? JSON.parse(localStorage.getItem("@elosworld:token")) : null;
    return userdata ? jwtDecode(userdata.token) : null;

};

const getIsAdmin = () => {
    var userData = localStorage.getItem("@elosworld:token") ? JSON.parse(localStorage.getItem("@elosworld:token")) : null;
    return userData ? userData.isAdmin : null;
}

const getIsVencido = () => {
    var userData = localStorage.getItem("@elosworld:token") ? JSON.parse(localStorage.getItem("@elosworld:token")) : null;
    return userData ? userData.isVencido : null;
}

const clearAvisosLidos = () => {
    localStorage.removeItem("@elosworld:ListaAvisosLidos");
}

const getToken = () => {
    var userdata = localStorage.getItem("@elosworld:token") ? JSON.parse(localStorage.getItem("@elosworld:token")) : null;
    return userdata ? userdata.token : null;
};

const setToken = (token) => {
    localStorage.setItem("@elosworld:token", JSON.stringify({ token }));
};

const removeToken = () => {
    localStorage.removeItem("@elosworld:token");
};

const tokenService = {
    getUserData,
    getIsAdmin,
    clearAvisosLidos,
    getIsVencido,
    getToken,
    setToken,
    removeToken
}

export default tokenService;