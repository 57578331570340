import axios from "./BaseService";

/**
 * Listar historico usuarios
 */
const getListarHistorico = (userData) => {
    return axios.post(process.env.REACT_APP_API_URL+"historicousuario/listar", userData);
}

/** 
 * Registrar historico usuario 
 * */
const registrarHistorico = (userData) => { 
    return axios.post(process.env.REACT_APP_API_URL+"historicousuario/registrar", userData);
}

/**
 * Atualizara historico usuario
 */
const atualizarHistorico = (userData) => {
    return axios.put(process.env.REACT_APP_API_URL+"historicousuario/atualizar", userData);
}

/** 
 * Remover historico
 */
const removerHistorico = (userData) => {
    return axios.delete(process.env.REACT_APP_API_URL+"historicousuario/remover", {data: userData});
}


const HistoricoUsuarioService = {
    getListarHistorico,
    registrarHistorico,
    atualizarHistorico,
    removerHistorico
}

export default HistoricoUsuarioService;