// src/components/ResetPassword.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service'; // Serviço para atualizar a senha

const ResetPassword = () => {
    const { token } = useParams(); // Captura o token da URL
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (newPassword !== confirmPassword) {
            setMessage("As senhas não correspondem.");
            setLoading(false);
            return;
        }

        try {
            await AuthService.updatePassword(token, newPassword); // Passar o token e a nova senha
            setMessage("Senha atualizada com sucesso!");
            setLoading(false);
            navigate('/login'); // Redireciona para a página de login
        } catch (error) {
            setMessage("Erro ao atualizar a senha.");
            setLoading(false);
        }
    };

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card card-container p-4">

                        {/* Logo da Plataforma usando o favicon */}
                        <div className="text-center mb-3">
                            <img
                                src="/favicon.ico"  // Caminho para o favicon.ico na pasta public
                                alt="Logo"
                                className="img-fluid"  // Ajusta o tamanho da logo
                                style={{ width: '64px' }} // Definir o tamanho desejado
                            />
                        </div>

                        {/* Título */}
                        <h2 className="text-center" style={{ color: '#000000' }}>Redefinir Senha</h2>

                        <form onSubmit={handleResetPassword}>
                            <div className="form-group">
                                <label htmlFor="newPassword">Nova Senha</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="newPassword"
                                    placeholder="Digite a nova senha"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirme a Nova Senha</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confirmPassword"
                                    placeholder="Confirme a nova senha"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>

                            {message && (
                                <div className="form-group">
                                    <div className={`alert ${message.includes('sucesso') ? 'alert-success' : 'alert-danger'}`} role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}

                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                                    {loading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        'Atualizar Senha'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
