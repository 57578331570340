var StateValue = {
    Unknown: 0,
    N:'Novo',
    EP: 'Em Processamento',
    ER: 'Erro',
    F: 'Finalizado',
    C: 'Criado'    
};

const getStatus = (key) => {
    var val = StateValue[key];
    return val;
}

export default getStatus;