// import axios from "axios";
import axios from "./BaseService";

/** listar usuarios */
const getUsuarios = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "usuario/listar", data);
};
/** registrar empresa */
const postRegistrarEmpresa = (empresaDados) => {
    return axios.post(process.env.REACT_APP_API_URL + "empresa/registrar", empresaDados);
}
/** atualizar empresa */
const putAtualizarEmpresa = (empresaDados) => {
    return axios.put(process.env.REACT_APP_API_URL + "empresa/atualizar", empresaDados);
}
/* remover empresa */
const deleteRemoverEmpresa = (uuidEmpresa) => {
    return axios.delete(process.env.REACT_APP_API_URL + "empresa/remover/"+uuidEmpresa);
}

const UserService = {
  getUsuarios,

}

export default UserService;