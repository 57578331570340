import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Message = ({ msg }) => {
  const [showPopup, setShowPopup] = useState({ isActive: false });

  const toggleShowInfoPopup = () => {
    setShowPopup({ isActive: true });
  }

  return (
    <div className='alert alert-info alert-dismissible fade show' role='alert' hidden={showPopup.isActive}>
      <button
        type='button'
        className='close'
        data-dismiss='alert'
        aria-label='Close'
        onClick={toggleShowInfoPopup}
      >
        <span aria-hidden='true'>&times;</span>
      </button>
      {msg}
    </div>
  );
};

Message.propTypes = {
  msg: PropTypes.string.isRequired
};

export default Message;
