import React, { useState, useRef, Fragment } from "react";
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import ImportacaoService from "../../services/importacaoservice"
import "../../style/general.css";
import ImportacaoModel from "../../models/importacao.model";
import Message from './Message';
import Progress from './Progress';

function CadastrarImportacao() {
    const [file, setFile] = useState('');
    const [fileName, setFilename] = useState('Escolha o Arquivo');

    let uploadedFile = { fileName: "", filePath: "" };
    const [message, setMessage] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const onChange = e => {
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
    };

    const onSubmit = async e => {
        e.preventDefault();
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + 'importacao/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    setUploadPercentage(
                        parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    );
                }
            });

            // Clear percentage
            setTimeout(() => setUploadPercentage(0), 100000000000);

            uploadedFile.fileName = res.data.fileName;
            uploadedFile.filePath = res.data.filePath;

            setMessage('Arquivo carregado. Descompactando e importando...');
            const res2 = await axios.post(process.env.REACT_APP_API_URL + 'importacao/decompress', uploadedFile);
            setMessage('Importação concluída');

            setUploadPercentage(0);
            setIsUploading(false);
        } catch (err) {
            // if (err.response.status === 500) {
            //     setMessage('There was a problem with the server');
            // } else {
            setMessage(err.message);
            // }
            setUploadPercentage(0);
            setIsUploading(false);
        }
    };

    return (
        <div className="container">
            <header className="jumbotron">
                <h3>Cadastrar Importação</h3>
            </header>

            {message ? <Message msg={message} /> : null}
            <form onSubmit={onSubmit}>
                <div className='custom-file mb-4'>
                    <input
                        type='file'
                        className='custom-file-input'
                        id='customFile'
                        onChange={onChange}
                    />
                    <label className='custom-file-label' htmlFor='customFile'>
                        {fileName}
                    </label>
                </div>

                <Progress percentage={uploadPercentage} />

                <input
                    type='submit'
                    value='Upload'
                    className='btn btn-primary btn-block mt-4'
                    disabled={isUploading}
                />
            </form>
            {uploadedFile ? (
                <div className='row mt-5'>
                    <div className='col-md-6 m-auto'>
                        <h3 className='text-center'>{uploadedFile.fileName}</h3>
                        <img style={{ width: '100%' }} src={uploadedFile.filePath} alt='' />
                    </div>
                </div>
            ) : null}
        </div>

    );
}

export default CadastrarImportacao;