import React, { useState, useEffect, useRef } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaPencil, FaPumpMedical, FaX } from "react-icons/fa6";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProdutoService from "../../services/produtos.service";
import CategoriaProdutoModel from "../../models/categoriaProduto.model"
import tokenService from "../../services/token.service";
import "../../style/general.css";
import Select from 'react-select'
const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const CategoriaProdutoListar = () => {

    /** UseState */
    const [categorias, setCategorias] = useState([]);
    const [categoriaAtualizar, setCategoriaAtualizar] = useState({});

    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [status, setStatus] = useState("");


    const [id, setId] = useState("");
    const [nomeAtualizar, setNomeAtualizar] = useState("");
    const [descricaoAtualizar, setDescricaoAtualizar] = useState("");
    const [statusAtualizar, setStatusAtualizar] = useState("");


    const [usuario, setUsuario] = useState("");
    const [isAdmin, setAdmin] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);
    const inputRef = useRef(null);
    const [previewSource, setPreviewSource] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showAtualizar, setShowAtualizar] = useState(false);
    const handleCloseAtualizar = () => setShowAtualizar(false);
    const handleShowAtualizar = () => setShowAtualizar(true);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const checkBtn = useRef();

    const statusList = [
        { label: "Ativo", value: "A" },
        { label: "Inativo", value: "I" }
    ]

    /** UseEffect para inicializar */
    useEffect(() => {
        let userData = tokenService.getUserData();
        setUsuario(userData);

        if (userData.permissoes.find((pi) => {
            return pi.includes("super-admin");
        })) {
            /** carregar categorias */
            carregarCategorias();
            return setAdmin(true);
        } else {
            /** carregar categorias */
            carregarCategorias();
            return setAdmin(false);
        }



    }, []);

    /** carregar categorias */
    const carregarCategorias = () => {
        setCategorias([]);
        ProdutoService.getListarCategorias().then(
            (response) => {
                let list = response.data.lista;
                if (list) {
                    setCategorias(list);
                }
            },
            (error) => {
                const aviso = (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Erro', aviso, 'error');
            });
    }

    const handleDelete = (produtoData) => {
        MySwal.fire({
            title: "Voce tem certeza?",
            text: `Ao confirmar voce ira remover a categoria ${produtoData.nome} do sistema`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar Remocao"
        }).then((result) => {
            if (result.isConfirmed) {
                ProdutoService.deleteCategoriaProduto({ id: produtoData.id }).then((r) => {
                    if (r.status === 200) {
                        MySwal.fire({
                            title: "Sucesso!",
                            text: "categoria removida",
                            icon: "success"
                        });
                        carregarCategorias();
                    } else {
                        MySwal.fire({
                            title: "Atencao!",
                            text: `Ocorreu o seguinte erro: ` + r,
                            icon: "error"
                        });
                    }
                }, (error) => {
                    MySwal.fire({
                        title: "Atencao!",
                        text: "Ocorreu o seguinte erro:  " + error,
                        icon: "error"
                    });
                })
            }
        });
    }

    /** Adicionar nova categoria */
    const onRegistrarCategoria = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            let produtoData = new CategoriaProdutoModel();
            Object.assign(produtoData, {
                nome: nome,
                descricao: descricao,
                status: status.value
            })
            ProdutoService.postRegistrarCategoriaProduto(produtoData).then(
                (dt) => {
                    if (dt.status === 200) {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Sucesso!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'success'
                        });
                        setNome("");
                        setDescricao("");
                        setStatus("inativo")
                        carregarCategorias();
                        handleClose(true);
                    } else {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Atencao!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'warning'
                        })
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    }

    /** atualizar categoria */
    const onAtualizarCategoria = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            let categoriaData = new CategoriaProdutoModel();
            Object.assign(categoriaData, {
                id: id,
                nome: nomeAtualizar,
                descricao: descricaoAtualizar,
                status: statusAtualizar ? statusAtualizar.value : ''
            });
            ProdutoService.putAtualizarCategoriaProduto(categoriaData).then(
                (dt) => {
                    if (dt.status === 200) {
                        setNomeAtualizar("");
                        setDescricaoAtualizar("");
                        setStatusAtualizar(null);
                        setId(null);
                        handleCloseAtualizar();
                        carregarCategorias();
                    } else {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Atencao!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'warning'
                        })
                    }
                })
        } else {
            setLoading(false);
            MySwal.fire({
                title: <strong>Atencao!</strong>,
                html: <i>Campos obrigatorios nao preenchidos</i>,
                icon: 'warning'
            })
        }
    }

    /** handlers */
    const onChangeNomeAtualizar = (event) => {
        const descricao = event.target.value;
        setNomeAtualizar(descricao);
    };
    const onChangeNome = (event) => {
        const descricao = event.target.value;
        setNome(descricao);
    };
    const onChangeDescricaoAtualizar = (event) => {
        const descricao = event.target.value;
        setDescricaoAtualizar(descricao);
    };
    const onChangeDescricao = (event) => {
        const descricao = event.target.value;
        setDescricao(descricao);
    };



    /** renderizacao */
    return (
        <div className="container">
            <header className="jumbotron">
                <div className="row">
                    <div className="col-9">
                        <h3>Categorias Produtos</h3>
                    </div>

                    <div className="col-3">
                        <button className="btn btn-primary btn-block" onClick={() => {
                            handleShow()
                        }}>
                            <span>
                                Adicionar Categoria
                            </span>
                        </button>
                    </div>

                </div>
            </header>
            <div className="row">
                <div className="col-12 text-center">
                    {
                        !categorias || categorias.length === 0 &&
                        <h4 style={{ color: "#000000" }}>Nenhum Produto encontrado.</h4>
                    }
                    {categorias.length > 0 &&
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Nome Categoria</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categorias.map((produto, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{produto.nome}</td>
                                                    <td>{produto.status == 'A' ? "Ativo" : "Inativo"}</td>
                                                    <td>
                                                        <Button ref={inputRef} style={{ marginRight: 20 }} variant="success" onClick={() => {
                                                            setId(produto.id);
                                                            setNomeAtualizar(produto.nome);
                                                            setDescricaoAtualizar(produto.descricao);
                                                            setStatusAtualizar(produto.tipo);
                                                            handleShowAtualizar()
                                                            setCategoriaAtualizar(produto);
                                                        }}>
                                                            <FaPencil />
                                                        </Button>
                                                        <Button ref={inputRef} variant="danger" onClick={() => {
                                                            handleDelete(produto)
                                                        }}>
                                                            <FaX />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            {/* Modal atualizar */}
            <Modal show={showAtualizar} onHide={handleCloseAtualizar}>
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Editar Produto - {categoriaAtualizar.nome}
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleCloseAtualizar}> X </Button>

                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onAtualizarCategoria} ref={form}>
                        <div className="form-group">
                            <label htmlFor="titulo">Nome Categoria</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={nomeAtualizar}
                                onChange={onChangeNomeAtualizar}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descricao</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="descricao"
                                value={descricaoAtualizar}
                                onChange={onChangeDescricaoAtualizar}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <Select options={statusList} onChange={(e) => setStatusAtualizar(e)} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAtualizar}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onAtualizarCategoria}>
                        Atualizar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal Nova Categoria */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Adicionar Categoria
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>

                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onRegistrarCategoria} ref={form}>
                        <div className="form-group">
                            <label htmlFor="nome">Nome Categoria</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nome"
                                value={nome}
                                onChange={onChangeNome}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descricao</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="descricao"
                                value={descricao}
                                onChange={onChangeDescricao}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <Select options={statusList} onChange={(e) => setStatus(e)} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onRegistrarCategoria}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default CategoriaProdutoListar