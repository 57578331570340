
const checkPermission = (requested, userData) => {
    if (userData) {
        if (userData.permissoes.find((pi) => {
            return pi.includes(requested);
        })) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

const PermissionUtil = {
    checkPermission,
    
}

export default PermissionUtil;