class UsuarioModel {
    constructor(uuid, email, password, idPerfil, token, dataVencimento, observacao, origemVenda, status) {
        this.uuid = uuid;
        this.email = email;
        this.password = password;
        this.idPerfil = idPerfil;
        this.token = token;
        this.status = status;
        this.dataVencimento = dataVencimento;
        this.observacao = observacao;
        this.origemVenda = origemVenda;
    }
}

export default UsuarioModel