import axios from "./BaseService";

/**
 * Listar Avisos
 */
const getListarTodos = () => {
    return axios.get(process.env.REACT_APP_API_URL+"avisos/listar/todos");
}

/**
 * Listar Avisos Ativos
 */
const getListarAtivos = () => {
    return axios.get(process.env.REACT_APP_API_URL+"avisos/listar");
}

/**
 * Registrar novo Aviso
 */
const postRegistrarAviso = (avisoData) => {
    return axios.post(process.env.REACT_APP_API_URL + "avisos/registrar", avisoData);
};

/**
 * Atualizar Aviso
 */
const putAtualizarAviso= (avisoData) => {
    return axios.put(process.env.REACT_APP_API_URL+"avisos/atualizar", avisoData);
}

/**
 * Remover Aviso
 */
const deleteAviso = (avisoData) => {
    return axios.delete(process.env.REACT_APP_API_URL+"avisos/remover", {data: avisoData});
}

const AvisosService = {
    getListarTodos,
    getListarAtivos,
    postRegistrarAviso,
    putAtualizarAviso,
    deleteAviso
}

export default AvisosService;