import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DualListBox from 'react-dual-listbox';
import EmpresaService from "../../services/empresa.service";
import tokenService from "../../services/token.service";
import UsuarioModel from "../../models/usuario.model";
import Perfilervice from "../../services/perfil.service";
import AuthService from "../../services/auth.service";
import PerfilModel from "../../models/perfil.model";

const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const CadastrarPerfil = () => {
    const form = useRef();
    const checkBtn = useRef();

    /* useState para armazenar as informacoes */
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [authData, setAuth] = useState({});
    const [isAdmin, setAdmin] = useState(false);

    /* form */
    const [titulo, setTitulo] = useState("");
    const [descricao, setDescricao] = useState("");
    const [uuidConta, setUuid] = useState("");
    const [selected, setSelected] = useState([]);
    const [listaPermissoes, setListaPermissoes] = useState([]);
    const [permissoesPerfil, setPermissaoPerfil] = useState([]);
    const [perfil, setPerfil] = useState({})


    useEffect(() => {
        carregarPermissoes();
        const loggedInUser = tokenService.getUserData();
        if (loggedInUser) {
            setAuth(loggedInUser);
            if (loggedInUser.permissoes.find((pi) => {
                return pi.includes("super-admin");
            })) {
                // carregar o dropdown
                return setAdmin(true);
            } else {
                return setAdmin(false);
            }
        }
    }, []);

    const onRegistrarPerfil = (e) => {
        e.preventDefault();
        // setMessage("");
        // setLoading(true);
        // form.current.validateAll();
        // if (checkBtn.current.context._errors.length === 0) {
        let perfilData = new PerfilModel();
        Object.assign(perfilData, {
            titulo: titulo,
            descricao: descricao,
            uuidempresa: uuidConta,
            permissoes: selected,
            status: 1
        })
        Perfilervice.postRegistrarPerfil(perfilData).then(
            (dt) => {
                if (dt.status === 200) {
                    // setLoading(false);
                    MySwal.fire({
                        title: <strong>Sucesso!</strong>,
                        html: <i>{dt.data.message}</i>,
                        icon: 'success'
                    });

                    // poe no localhost
                    //   let udt = tokenService.getUserData();
                    //   directCheck(udt.permissoes);            
                    //   window.location.reload();            
                } else {
                    // setLoading(false);
                    MySwal.fire({
                        title: <strong>Atencao!</strong>,
                        html: <i>{dt.data.message}</i>,
                        icon: 'warning'
                    })
                }

            }, (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setLoading(false);
                // setMessage(resMessage);
            })
        // } else {
        //     setLoading(false);
        // }
    }


    /* Handle do titulo */
    const handleTitulo = (e) => {
        const tit = e.target.value;
        setTitulo(tit);
    }

    /* handle da descricao */
    const handleDescricao = (e) => {
        const desc = e.target.value;
        setDescricao(desc);
    }

    /* Carregar Permissoes */
    const carregarPermissoes = () => {
        
        Perfilervice.getListarPermissoes().then(
            (dt) => {
                if (dt.data.lista) {
                    let perm = dt.data.lista.map((item) => {
                        return {
                            value: item.id,
                            label: item.titulo
                        }
                    })
                    setListaPermissoes(perm);
                }
                // setListaPermissoes(dt.data.lista);
            },
            (error) => {
                const aviso = (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Erro', aviso, 'error');
            }
        );
    }

    const handleAddPermission = (e) => {
        setSelected(e);
        // setPermissaoPerfil((prevItens) => [
        //     ...prevItens,
        //     {
        //         idPermissao: e,
        //         idPerfil: perfil.id
        //     }
        // ])
        setPermissaoPerfil(selected);
    }

    /** retorna o html */
    return (
        <>
            <div className="container">
                <header className="jumbotron">
                    <h3>Cadastrar Perfil</h3>
                </header>
            </div>
            <div className="row">
                <div className="col-md-11">
                    <Form onSubmit={onRegistrarPerfil} ref={form}>
                        <div className="form-group">
                            <label htmlFor="titulo">Titulo</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="titulo"
                                value={titulo}
                                onChange={handleTitulo}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descrição</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="descricao"
                                value={descricao}
                                onChange={handleDescricao}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <DualListBox
                                options={listaPermissoes}
                                selected={selected}
                                onChange={handleAddPermission}
                            />
                        </div>
                        {/* {isAdmin &&
                        < div className="form-group">
                            <label htmlFor="tipo">Conta Relacionada</label>
                            <Dropdown>
                                <Dropdown.Toggle variant="success">
                                    {uuidEmpresa.NomeConta ? uuidEmpresa.NomeConta : "Selecione"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        contasList.map((conta, index) => {
                                            return (
                                                <>
                                                    <Dropdown.Item key={index + conta.uuid} href="#" onClick={() => {
                                                        setConta(conta)
                                                        carregarPerfis(conta.uuid)
                                                    }} value={conta}>
                                                        {conta.NomeConta}
                                                    </Dropdown.Item>
                                                </>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    }
                    */}
                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Cadastrar</span>
                            </button>
                        </div>

                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form >
                </div>
            </div >
        </>
    )
}

export default CadastrarPerfil;