import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Pagination } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import RoboService from "../../services/robo.service";
import tokenService from "../../services/token.service";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { saveAs } from 'file-saver';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string';


const MySwal = withReactContent(Swal)

const ResponseBot = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  /** UseState para os dados  */
  const [RespostasBot, setListaRespostas] = useState([]);
  const [RespostasBotTikTok, setListaRespostasTikTok] = useState([]);
  const [authenticated, setauthenticated] = useState({});
  const [numberRecords, setNumberOfRecords] = useState(0);
  const [numberInstaRecords, setNumberOfInstaRecords] = useState(0);
  const [numberTikTokRecords, setNumberOfTiktokRecords] = useState(0);
  const [limite, setLimite] = useState(10);
  const [paginas, setPaginas] = useState(0);
  const [empresaUUID, setEmpresaUUID] = useState("");
  const [key, setKey] = useState('first');
  const [show, setShow] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const totalPages = Math.ceil(numberRecords / itemsPerPage);

  const totalInstaPages = Math.ceil(numberInstaRecords / itemsPerPage);
  const totalTiktokPages = Math.ceil(numberTikTokRecords / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = RespostasBot.slice(startIndex, endIndex);
  const currentPageDataTiktok = RespostasBotTikTok.slice(startIndex, endIndex);

  const [qtdTelefonesTiktok, setQtdTelefonesTiktok] = useState(0);
  const [qtdTelefonesInstagram, setQtdTelefonesInstagram] = useState(0);


  const [csvData, setCsvData] = useState([]);

  const [columnsToExport, setColumnsToExport] = useState([
    "#",
    "alvo busca",
    "seguidor",
    "contato",
    "adicional",
    "telefone",
    "site",
    "whatsapp"
  ]);

  const headers = [
    "#",
    "alvo busca",
    "seguidor",
    "contato",
    "adicional",
    "telefone",
    "site",
    "whatsapp"
  ]


  /* checar se usuario esta logado */
  useEffect(() => {
    const loadPage = () => {
      const loggedInUser = tokenService.getUserData();
      if (loggedInUser) {
        setEmpresaUUID(loggedInUser.uuidEmpresa);
        setauthenticated(loggedInUser);
        carregarRespostas(loggedInUser.uuidEmpresa);
        if (parsed) {
          setKey(parsed.tipo == "Instagram" ? 'first' : 'second');
        }
      }
    }
    loadPage();
  }, []);


  const carregarRespostas = (uuidEmp) => {
    setListaRespostas([]);
    setListaRespostasTikTok([]);
    RoboService.getRespostas(uuidEmp).then(
      (response) => {
        setLimite(10);

        /** melhorias de filtro */
        let list = response.data.lista.filter((d) => {
          if (d.telefone != '-' && d.telefone != null || d.contato != null && d.contato != '-'
            || d.whatsapp != null && d.whatsapp != '-') {
            return d;
          }
        })
        /** filtrar usuario selecionado */
        if (parsed.usuario) {
          list = list.filter((x) => x.alvo === parsed.usuario);
        }

        /** Cotador tel */
        let ctnTiktok = 0;
        let ctnInsta = 0;

        var telList = list.filter((x) => {
          if (x.telefone) {
            return x
          }
        })

        telList.forEach((x) => {
          switch (x.origem) {
            case 'TikTok':
              ctnTiktok++;
              break;
            case 'Instagram':
              ctnInsta++;
              break;
            default:
            // console.log(`Origem desconhecida: ${x.origem}`);
          }
        });

        setQtdTelefonesInstagram(ctnInsta);
        setQtdTelefonesTiktok(ctnTiktok);
        var listaBase = list;
        var listaInsta = list;
        var listaTiktok = list;

        let tikRec = listaTiktok.filter((ti) => {          
          if (ti.origem == "Tiktok" || ti.origem == "TikTok") {
            return ti
          }
        })
        setNumberOfTiktokRecords(tikRec.length);


        /** define as paginas para insta e tiktok */
        let insRec = listaInsta.filter((di) => {
          if (di.origem == "Instagram") {
            return di;
          }
        })
        setNumberOfInstaRecords(insRec.length);



debugger
        setListaRespostas(list);
        setListaRespostasTikTok(tikRec);
        setNumberOfRecords(Number(response.data.lista.length));
        setPaginas(Math.round(response.data.lista.length / limite));
        // console.log(`qtd`, Math.round(response.data.lista.length / limite))
        // console.log(paginas)

      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <i>{error.messsage}</i>,
          icon: 'error'
        })
      }
    );
  }

  /** funcao de formatacao de telefone */
  const check55 = (num) => {
    // Adiciona o prefixo "+55" caso o número de telefone não o possua
    if (!num.startsWith('+55') && num != '-') {
      num = '+55 ' + num;
    }
    return num;

  }

  /** export to csv  */
  const convertToCSV = (headers, items) => {

    headers = columnsToExport;
    const csvRows = [];
    const headersRow = headers.join(",");
    csvRows.push(headersRow);

    items.forEach((item) => {
      const valuesRow = headers.map((header) => `"${header == "Alvo Busca" ? item["alvo"] : header == "Seguidor" ? item["follower"] : item[header.toLowerCase()]}"`).join(",");
      csvRows.push(valuesRow);
    });

    return csvRows.join("\n");
  };

  // const exportToCSV = (columns) => {
  //   const headers = ["#", "Alvo Busca", "Seguidor", "Contato", "Adicional", "Telefone", "Site", "Whatsapp"];
  //   const csvDataToExport = [];

  //   RespostasBot.forEach((item, index) => {
  //     const newItem = {};
  //     columns.forEach((column, index) => {
  //       newItem[column] = item[column];
  //     });
  //     newItem["#"] = index + 1;
  //     csvDataToExport.push(newItem);
  //   });

  //   const csvDataString = convertToCSV(headers, csvDataToExport);
  //   const blob = new Blob([csvDataString], { type: "text/csv;charset=utf-8;" });
  //   saveAs(blob, "respostas_bot.csv");
  // };

  const exportToCSV = () => {
    const headers = [
      "#",
      "alvo busca",
      "seguidor",
      "contato",
      "adicional",
      "telefone",
      "site",
      "whatsapp"
    ];

    let csvDataToExport = [];


    RespostasBot.forEach((item, index) => {
      const newItem = {};
      headers.forEach((header, index) => {
        if (columnsToExport.includes(header.toLowerCase())) {
          newItem[header] =
            header.toLowerCase() === "alvo busca"
              ? item["alvo"]
              : header.toLowerCase() === "seguidor"
                ? item["follower"]
                : item[header.toLowerCase()];
        }
      });
      newItem["#"] = index + 1;
      csvDataToExport.push(newItem);
    });

    csvDataToExport = csvDataToExport.filter(rt => (rt.telefone !== null && rt.telefone !== ""));

    const csvDataString = convertToCSV(headers, csvDataToExport);
    const blob = new Blob([csvDataString], {
      type: "text/csv;charset=utf-8;"
    });
    saveAs(blob, "respostas_bot.csv");
  };

  const handleClose = () => {
    setShow(false);
  }

  let instLength = currentPageData.filter((d) => {
    if (d.origem == "Instagram") {
      return d
    }
  });
  let tikLength = currentPageDataTiktok.filter((d) => {
    if (d.origem == "TikTok") {
      return d
    }
  });


  if (!authenticated) {
    // Redirect
    <Navigate replace to="/login" />;
  } else {
    return (
      <div>
        <header className="jumbotron">
          <h3>Respostas Bot</h3>
        </header>
        <div className="row">
          <div className="col-md-11"></div>
          <div className="col-md-1">
            <button className="btn btn-primary" onClick={() => { setShow(true); }}>Export to CSV</button>
          </div>
        </div>
        <Tabs
          defaultActiveKey="first"
          activeKey={key}
          onSelect={(k) => setKey(k)} >
          <Tab eventKey="first" title={"Instagram (" + qtdTelefonesInstagram + ")"}>
            <div className="ml-3 mr-3" style={{ width: '96%' }}>
              <div className="table-responsive ml-3 mr-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Alvo Busca</th>
                      <th>Seguidor</th>
                      <th>Contato</th>
                      <th>Adicional</th>
                      <th>Telefone</th>
                      <th>Site</th>
                      <th>Whatsapp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {

                      currentPageData.map((resposta, index) => {

                        if (resposta.origem === 'Instagram' && instLength.length > 0) {
                          return (
                            <tr key={index}>
                              <td>{resposta.alvo}</td>
                              <td>{resposta.follower}</td>
                              <td>{resposta.contato ? resposta.contato : '-'}</td>
                              <td>{resposta.adicional ? resposta.adicional : '-'}</td>
                              <td>{resposta.telefone ? check55(resposta.telefone) : '-'}</td>
                              <td>{resposta.site ? resposta.site : '-'}</td>
                              <td>{resposta.whatsapp ? resposta.whatsapp : "-"}</td>
                            </tr>
                          )
                        } else {
                          <h4 style={{ color: "#000000" }}>Nenhuma resposta encontrada.</h4>
                        }
                      })

                    }
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-1">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalInstaPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(data) => {
                        setCurrentPage(data.selected + 1);
                      }}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousLinkClassName={"page-item previous"}
                      previousLinkLabelClassName={"page-link"}
                      nextLinkClassName={"page-item next"}
                      nextLinkLabelClassName={"page-link"}
                      breakLinkClassName={"page-item"}
                      breakLinkLabelClassName={"page-link"}
                      disabledClassName={"disabled"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="second" title={"TikTok (" + qtdTelefonesTiktok + ")"}>
            <div className="ml-3 mr-3" style={{ width: '96%' }}>
              <div className="table-responsive ml-3 mr-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Alvo Busca</th>
                      <th>Seguidor</th>
                      <th>Contato</th>
                      <th>Adicional</th>
                      <th>Telefone</th>
                      <th>Site</th>
                      <th>Whatsapp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {

                      currentPageDataTiktok.map((resposta, index) => {

                        if (resposta.origem === 'TikTok') {
                          return (
                            <tr key={index}>
                              <td>{resposta.alvo}</td>
                              <td>{resposta.follower}</td>
                              <td>{resposta.contato ? resposta.contato : '-'}</td>
                              <td>{resposta.adicional ? resposta.adicional : '-'}</td>
                              <td>{resposta.telefone ? check55(resposta.telefone) : '-'}</td>
                              <td>{resposta.site ? resposta.site : '-'}</td>
                              <td>{resposta.whatsapp ? resposta.whatsapp : "-"}</td>
                            </tr>
                          )
                        } else {
                          <h4 style={{ color: "#000000" }}>Nenhuma resposta encontrada.</h4>
                        }
                      })

                    }
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-1">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalTiktokPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(data) => {
                        setCurrentPage(data.selected + 1);
                      }}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousLinkClassName={"page-item previous"}
                      previousLinkLabelClassName={"page-link"}
                      nextLinkClassName={"page-item next"}
                      nextLinkLabelClassName={"page-link"}
                      breakLinkClassName={"page-item"}
                      breakLinkLabelClassName={"page-link"}
                      disabledClassName={"disabled"}
                    />
                  </div>
                </div>

              </div>
            </div>
          </Tab>
        </Tabs>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <h3>
                Exportar CSV
              </h3>
            </Modal.Title>
            <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="columnsToExport">
              <Form.Label style={{ color: "#000000" }}>
                Selecione as colunas a serem exportadas:
              </Form.Label>
              {headers.map((header, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={header}
                  style={{ 'color': '#000000' }}
                  checked={columnsToExport.includes(header.toLowerCase())}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setColumnsToExport([...columnsToExport, header.toLowerCase()]);
                    } else {
                      setColumnsToExport(
                        columnsToExport.filter(
                          (col) => col !== header.toLowerCase()
                        )
                      );
                    }
                  }}
                />
              ))}
            </Form.Group>
            <button className="btn btn-primary mt-3" onClick={() => exportToCSV(["origem", "alvo", "follower", "contato", "adicional", "telefone", "site", "whatsapp"])}>Exportar</button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ResponseBot;
