const formatarData = (dt) => {
    if (dt) {
        let base = dt.substring(0, 10);
        const [year, month, day] = base.split('-');
        const formattedDate = [day, month, year].join('/');
        return formattedDate;
    } else {
        return null
    }
}

const formatarDataHora = (dt) => {
    if (dt) {
        const date = new Date(dt);
        return `${date.toLocaleDateString('pt-BR')} ${date.toLocaleTimeString()}`;
      } else {
        return null;
      }
}


const dataUtils = {
    formatarData,
    formatarDataHora
}

export default dataUtils