import React, { useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProdutoService from "../../services/produtos.service";
import "../../style/general.css";
import ProdutoModel from "../../models/produto.model";

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const CadastrarProduto = () => {
    const MySwal = withReactContent(Swal);

    /**
    *  UseRefs
    */
    const form = useRef();
    const checkBtn = useRef();

    /**
     * useState
     */
    const [titulo, setTitulo] = useState([]);
    const [tipo, setTipo] = useState("");
    const [descricao, setDescricao] = useState("");
    const [endereco, setEndereco] = useState("");
    const [imagem, setImagem] = useState("");
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [previewSource, setPreviewSource] = useState(null);

    /**
     * handle dos campos
     */
    const onChangeTitulo = (event) => {
        const titulo = event.target.value;
        setTitulo(titulo);
    };
    const onChangeTipo = (event) => {
        const tipo = event.target.value;
        setTipo(tipo);
    };
    const onChangeDescricao = (event) => {
        const descricao = event.target.value;
        setDescricao(descricao);
    };
    const onChangeEndereco = (event) => {
        const endereco = event.target.value;
        setEndereco(endereco);
    };
    const onChangeImagem = (event) => {
        let imagem = URL.createObjectURL(event.target.files[0]);
        setImagem(imagem);
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setImagem(URL.createObjectURL(file));
        previewFile(file);
    };
    const onChangeStatus = (event) => {
        const status = event.target.checked ? "Ativo" : "Inativo";
        setStatus(status);
    };

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewSource(reader.result);
        };
    };

    /**
     * Funcao para quando rodar o cadastro
     */
    const onRegistrarProduto = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            let produtoData = new ProdutoModel();
            Object.assign(produtoData, {
                titulo: titulo,
                tipo: tipo,
                descricao: descricao,
                endereco: endereco,
                imagem: imagem,
                status: status.value
            })
            ProdutoService.postRegistrarProduto(produtoData).then(
                (dt) => {
                    if (dt.status === 200) {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Sucesso!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'success'
                        });
                        setTitulo("");
                        setTipo(null);
                        setDescricao("");
                        setEndereco("");
                        setImagem(null);
                        setStatus("inativo")
                        setPreviewSource(null);
                        setImagem(null)
                        
                    } else {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Atencao!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'warning'
                        })
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    }

    return (
        <div className="container">
            <header className="jumbotron">
                <h3>Registrar Produto</h3>
            </header>
            <div className="row">
                <div className="col-12">
                    <Form onSubmit={onRegistrarProduto} ref={form}>
                        <div className="form-group">
                            <label htmlFor="titulo">Titulo Produto</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={titulo}
                                onChange={onChangeTitulo}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="tipo">Tipo</label>
                            <Dropdown>
                                <Dropdown.Toggle variant="default">
                                    {tipo}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => { setTipo("Link") }} >
                                        Link
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => { setTipo("Executavel") }} >
                                        Executavel
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descricao</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="descricao"
                                value={descricao}
                                onChange={onChangeDescricao}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="endereco">Endereco</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="endereco"
                                value={endereco}
                                onChange={onChangeEndereco}
                                validations={[required]}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="endereco">Imagem</label>
                            <input type="file" name="image" onChange={handleFileChange} />
                            {previewSource && (
                                <img src={previewSource} alt="chosen" style={{ height: '300px', width: '300px' }} />
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <Dropdown>
                                <Dropdown.Toggle variant="default">
                                    {status.label}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => { setStatus({ label: "Ativo", value: "A" }) }} >
                                        Ativo
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => { setStatus({ label: "Inativo", value: "I" }) }} >
                                        Inativo
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Cadastrar</span>
                            </button>
                        </div>

                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default CadastrarProduto