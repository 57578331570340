import React, { useState, useEffect, useRef } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaPencil, FaX } from "react-icons/fa6";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProdutoService from "../../services/produtos.service";
import ProdutoModel from "../../models/produto.model";
import tokenService from "../../services/token.service";
import "../../style/general.css";
import Select from 'react-select'
import arrayUtils from "../../utils/arrayUtils";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';

const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const ListarProduto = () => {

    /**
    *  UseState
    * 
    * 
    */
    const [idProduto, setIdProduto] = useState("");
    const [titulo, setTitulo] = useState([]);
    const [tipo, setTipo] = useState("");
    const [descricao, setDescricao] = useState("");
    const [categoriaProduto, setCategoriaProduto] = useState("");
    const [endereco, setEndereco] = useState("");
    const [imagem, setImagem] = useState(null);
    const [video, setVideo] = useState("");
    const [status, setStatus] = useState("");
    const [produtosCadastrados, setProdutosCadastrados] = useState([]);
    const [produtosCadastradosBackup, setProdutosCadastradosBackup] = useState([]);

    // update
    const [tituloAtualizar, setTituloAtualizar] = useState([]);
    const [tipoAtualizar, setTipoAtualizar] = useState("");
    const [categoriaProdutoAtualizar, setCategoriaProdutoAtualizar] = useState("");
    const [descricaoAtualizar, setDescricaoAtualizar] = useState("");
    const [enderecoAtualizar, setEnderecoAtualizar] = useState("");
    const [imagemAtualizar, setImagemAtualizar] = useState("");
    const [videoAtualizar, setVideoAtualizar] = useState("");
    const [statusAtualizar, setStatusAtualizar] = useState("");
    const [produtoAtualizar, setProdutoAtualizar] = useState("");


    const [currentUser, setCurrentUser] = useState(undefined);
    const inputRef = useRef(null);
    const [previewSource, setPreviewSource] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showAtualizar, setShowAtualizar] = useState(false);
    const handleCloseAtualizar = () => setShowAtualizar(false);
    const handleShowAtualizar = () => setShowAtualizar(true);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const checkBtn = useRef();
    const [categoriaList, setCategoriaList] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [filtroValor, setValorFiltro] = useState("");

    /** status dropdown */
    const statusList = [
        { value: "A", label: "Ativo" },
        { value: "I", label: "Inativo" }
    ]
    /** tipo dropdown */
    const tipoList = [
        { value: "Link", label: "Link" },
        { value: "Executavel", label: "Executavel" }
    ]

    /** Editor */
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setDescricao(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const onEditorStateAtualizarChange = (editorState) => {
        setEditorState(editorState)
        setDescricaoAtualizar(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };



    /** logged user */
    useEffect(() => {
        const user = tokenService.getUserData();
        if (user) {
            setCurrentUser(user);
        }
    }, []);
    /**
     *  carregar Lista 
     */
    useEffect(() => {
        carregarProdutos();
        carregarCategorias();
    }, []);

    /**
         * handle dos campos
         */
    const onChangeTitulo = (event) => {
        const titulo = event.target.value;
        setTitulo(titulo);
    };
    const onChangeTipo = (event) => {
        const tipo = event.target.value;
        setTipo(tipo);
    };

    const onChangeCategoria = (event) => {
        const categoriaProduto = event.target.value;
        setCategoriaProduto(categoriaProduto);
    };
    const onChangeDescricao = (event) => {
        const descricao = event.target.value;
        setDescricao(descricao);
    };
    const onChangeEndereco = (event) => {
        const endereco = event.target.value;
        setEndereco(endereco);
    };
    const onChangeVideo = (event) => {
        const video = event.target.value;
        setVideo(video);
    };
    const onChangeImagem = (event) => {
        // let imagem = URL.createObjectURL(event.target.files[0]);
        let imagem = event.target.files[0];
        setImagem(imagem);
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // setImagem(URL.createObjectURL(file));
        setImagem(file);
        previewFile(file);
    };
    const onChangeStatus = (event) => {
        const status = event.target.checked ? "Ativo" : "Inativo";
        setStatus(status);
    };

    const previewFile = (file) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewSource(reader.result);
        };
    };


    /** filtrar */
    const onFiltrar = (e) => {
        if (filtroValor !== "") {
            const filterBase = produtosCadastradosBackup;
            const filtered = filterBase.filter((dt) => {
                if (dt["titulo"] !== null) {
                    return dt["titulo"].toLowerCase().includes(filtroValor.toLowerCase());
                }
            });
            setProdutosCadastrados(filtered);
        } else {
            setProdutosCadastrados(produtosCadastradosBackup);
        }
    };

    // Atualizar

    const onChangeTituloAtualizar = (event) => {
        const titulo = event.target.value;
        setTituloAtualizar(titulo);
    };
    const onChangeTipoAtualizar = (event) => {
        const tipo = event.value;
        setTipoAtualizar(tipo);
    };
    const onChangeDescricaoAtualizar = (event) => {
        const descricao = event.target.value;
        setDescricaoAtualizar(descricao);
    };
    const onChangeEnderecoAtualizar = (event) => {
        const endereco = event.target.value;
        setEnderecoAtualizar(endereco);
    };
    const onChangeVideoAtualizar = (event) => {
        const video = event.target.value;
        setVideoAtualizar(video);
    };
    const onChangeCategoriaAtualizar = (event) => {
        const video = event.value;
        setCategoriaProdutoAtualizar(video);
    };
    const onChangeImagemAtualizar = (event) => {
        let imagem = URL.createObjectURL(event.target.files[0]);
        setImagemAtualizar(imagem);
    };
    const onChangeStatusAtualizar = (event) => {
        // const status = event.target.checked ? "Ativo" : "Inativo";
        const status = event.value;
        setStatusAtualizar(status);
    };

    const checkPermission = (requested) => {
        if (currentUser) {
            if (currentUser.permissoes.find((pi) => {
                return pi.includes(requested);
            })) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const carregarProdutos = () => {
        setProdutosCadastrados([]);
        setProdutosCadastradosBackup([]);
        ProdutoService.getListarTodos().then(
            (response) => {
                setProdutosCadastrados(response.data.lista);
                setProdutosCadastradosBackup(response.data.lista);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Erro', _content, 'error');
            }
        )
    }

    const carregarCategorias = () => {
        setCategoriaList([]);
        ProdutoService.getListarCategorias().then(
            (response) => {
                if (response.data.lista) {
                    let options = response.data.lista.map((d) => {
                        return { value: d.id, label: d.nome }
                    })
                    setCategoriaList(options);
                }
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Swal.fire('Erro', _content, 'error');
            }
        )
    }

    const handleDelete = (produtoData) => {
        MySwal.fire({
            title: "Voce tem certeza?",
            text: `Ao confirmar voce ira remover o produto ${produtoData.titulo} do sistema`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar Remocao"
        }).then((result) => {
            if (result.isConfirmed) {
                ProdutoService.deleteRemoverProduto(produtoData.id).then((r) => {
                    if (r.status === 200) {
                        MySwal.fire({
                            title: "Sucesso!",
                            text: "Produto removido",
                            icon: "success"
                        });
                        carregarProdutos();
                    } else {
                        MySwal.fire({
                            title: "Atencao!",
                            text: `Ocorreu o seguinte erro: ` + r,
                            icon: "error"
                        });
                    }
                }, (error) => {
                    MySwal.fire({
                        title: "Atencao!",
                        text: "Ocorreu o seguinte erro:  " + error,
                        icon: "error"
                    });
                })
            }
        });
    }

    /**
     * Funcao para quando rodar o cadastro
     */
    const onRegistrarProduto = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            let produtoData = new ProdutoModel();
            Object.assign(produtoData, {
                titulo: titulo,
                tipo: tipo.value,
                descricao: descricao,
                endereco: endereco,
                idCategoriaProduto: categoriaProduto ? categoriaProduto.value : ``,
                imagem: imagem ? imagem.name : '',
                video: video,
                status: status.value
            })

            ProdutoService.postRegistrarProduto(produtoData, imagem).then(
                (dt) => {
                    if (dt.status === 200) {

                        /** subir a imagem  */
                        ProdutoService.postRegistrarImagemProduto(imagem).then(
                            (dt) => {
                                if (dt.status === 200) {
                                    setLoading(false);
                                    MySwal.fire({
                                        title: <strong>Sucesso!</strong>,
                                        html: <i>{dt.data.message}</i>,
                                        icon: 'success'
                                    });
                                    setTitulo("");
                                    setTipo(null);
                                    setCategoriaProduto(null);
                                    setDescricao("");
                                    setEndereco("");
                                    setImagem(null);
                                    setStatus("inativo")
                                    setPreviewSource(null);
                                    setImagem(null)
                                    setVideo("");
                                    handleClose(true);
                                    carregarProdutos();
                                } else {
                                    setLoading(false);
                                    MySwal.fire({
                                        title: <strong>Atencao!</strong>,
                                        html: <i>{dt.data.message}</i>,
                                        icon: 'warning'
                                    })
                                }
                            }
                        )
                    } else {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Atencao!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'warning'
                        })
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    }

    /** Recuperar o nome da categoria */
    const getNomeCategoria = (e) => {
        const data = categoriaList.filter((d) => {
            return d.value === e
        });
        if (data.length > 0) {
            return data[0].label
        } else {
            return null
        }
    }

    // Atualizar produtos
    const onAtualizarProduto = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            let produtoData = new ProdutoModel();
            Object.assign(produtoData, {
                id: idProduto,
                titulo: tituloAtualizar,
                tipo: tipoAtualizar,
                idCategoriaProduto: categoriaProdutoAtualizar ? categoriaProdutoAtualizar : 0,
                descricao: descricaoAtualizar,
                endereco: enderecoAtualizar,
                imagem: imagem ? imagem.name : imagemAtualizar,
                video: videoAtualizar,
                status: statusAtualizar ? statusAtualizar : "A"
            });
            ProdutoService.putAtualizarProduto(produtoData).then(
                (dt) => {
                    if (dt.status === 200) {
                        /** se houver nova imagem ele sobe */
                        if (imagem && imagem.name) {
                            /** subir a imagem  */
                            ProdutoService.postRegistrarImagemProduto(imagem).then(
                                (dt) => {
                                    if (dt.status === 200) {
                                        setLoading(false);
                                        MySwal.fire({
                                            title: <strong>Sucesso!</strong>,
                                            html: <i>{dt.data.message}</i>,
                                            icon: 'success'
                                        });
                                        setTituloAtualizar("");
                                        setTipoAtualizar(null);
                                        setCategoriaProdutoAtualizar(null);
                                        setDescricaoAtualizar("");
                                        setEnderecoAtualizar("");
                                        setImagemAtualizar(null);
                                        setStatusAtualizar("inativo")
                                        setPreviewSource(null);
                                        setImagemAtualizar(null);
                                        setVideoAtualizar(null);
                                        handleCloseAtualizar();
                                        carregarProdutos();
                                    } else {
                                        setLoading(false);
                                        MySwal.fire({
                                            title: <strong>Atencao!</strong>,
                                            html: <i>{dt.data.message}</i>,
                                            icon: 'warning'
                                        })
                                    }
                                })
                        } else {
                            setLoading(false);
                            MySwal.fire({
                                title: <strong>Sucesso!</strong>,
                                html: <i>{dt.data.message}</i>,
                                icon: 'success'
                            });
                            setTituloAtualizar("");
                            setTipoAtualizar(null);
                            setCategoriaProdutoAtualizar(null);
                            setDescricaoAtualizar("");
                            setEnderecoAtualizar("");
                            setImagemAtualizar(null);
                            setStatusAtualizar("inativo")
                            setPreviewSource(null);
                            setImagemAtualizar(null);
                            setVideoAtualizar(null);
                            handleCloseAtualizar();
                            carregarProdutos();
                        }

                    } else {
                        setLoading(false);
                    }
                }, (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                    handleCloseAtualizar();
                }
            );
        } else {
            setLoading(false);
            MySwal.fire({
                title: <strong>Atencao!</strong>,
                html: <i>Campos obrigatorios nao preenchidos</i>,
                icon: 'warning'
            })
        }
    }

    return (
        <div className="container">
            <header className="jumbotron">
                <div className="row">
                    <div className="col-9">
                        <h3>Produtos Registrados</h3>
                    </div>
                    {
                        checkPermission("adicionar-produto") && (
                            <div className="col-3">
                                <button className="btn btn-primary btn-block" onClick={() => {
                                    handleShow()
                                }}>
                                    <span>
                                        Adicionar Produto
                                    </span>
                                </button>
                            </div>
                        )
                    }
                </div>
            </header>
            {/* Filtro */}
            <Form>
                <div className="row">
                    <div className="col-8"></div>
                    <div className="col-3">
                        <div className="form-group">
                            <Input
                                type="text"
                                value={filtroValor}
                                onChange={(e) => setValorFiltro(e.target.value)}
                                placeholder="Enter filter value"
                                className="form-control custom-input"
                            />
                        </div>
                    </div>
                    <div className="col-1 text-right mb-3" style={{ marginLeft: "-2%" }}>
                        <Button variant="primary" className="btn-custom" onClick={onFiltrar}>
                            Filtrar
                        </Button>
                    </div>
                    <div className="col-1"></div>
                </div>
            </Form>
            <div className="row">
                <div className="col-12 text-center">
                    {
                        produtosCadastrados.length === 0 &&
                        <h4 style={{ color: "#000000" }}>Nenhum Produto encontrado.</h4>
                    }
                    {produtosCadastrados.length > 0 &&
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Nome Produto</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    produtosCadastrados.map((produto, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td><a href={produto.endereco} target="_Blank">{produto.titulo}</a></td>
                                                    <td>{produto.status == 'A' ? "Ativo" : "Inativo"}</td>
                                                    <td>
                                                        <Button ref={inputRef} style={{ marginRight: 20 }} variant="success" onClick={() => {
                                                            setProdutoAtualizar(produto);
                                                            setIdProduto(produto.id);
                                                            setTituloAtualizar(produto.titulo);
                                                            setTipoAtualizar(produto.tipo);
                                                            setCategoriaProdutoAtualizar(produto.idCategoriaProduto);
                                                            setEditorState(EditorState.createWithContent(
                                                                ContentState.createFromBlockArray(
                                                                    convertFromHTML(produto.descricao)
                                                                )
                                                            ))
                                                            setDescricaoAtualizar(produto.descricao);
                                                            setEnderecoAtualizar(produto.endereco);
                                                            setImagemAtualizar(produto.imagem);
                                                            setVideoAtualizar(produto.video);
                                                            setStatusAtualizar(produto.status);
                                                            handleShowAtualizar()
                                                        }}>
                                                            <FaPencil />
                                                        </Button>
                                                        <Button ref={inputRef} variant="danger" onClick={() => {
                                                            handleDelete(produto)
                                                        }}>
                                                            <FaX />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            {/* Modal atualizar */}
            <Modal show={showAtualizar} onHide={handleCloseAtualizar} size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Editar Produto - {tituloAtualizar}
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleCloseAtualizar}> X </Button>

                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onRegistrarProduto} ref={form}>
                        <div className="form-group">
                            <label htmlFor="titulo">Titulo Produto</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={tituloAtualizar}
                                onChange={onChangeTituloAtualizar}                                
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="tipo">Tipo</label>
                            <Select options={tipoList} onChange={(e) => onChangeTipoAtualizar(e)}
                                value={tipoList.value} defaultValue={tipoList[arrayUtils.getIndexArray(tipoList, tipoAtualizar)]} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Categoria Produto">Categoria Produto</label>
                            <Select options={categoriaList} onChange={(e) => onChangeCategoriaAtualizar(e)}
                                value={categoriaList.value} defaultValue={categoriaList[arrayUtils.getIndexArray(categoriaList, categoriaProdutoAtualizar)]} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descricao</label>
                      <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateAtualizarChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="endereco">Endereco</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="endereco"
                                value={enderecoAtualizar}
                                onChange={onChangeEnderecoAtualizar}
                                validations={[required]}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="endereco">Imagem</label>
                            <br></br>
                            {
                                imagemAtualizar !== null &&
                                <label className="text-center" onChangeCapture={() => {
                                    setImagem(null)
                                }}>
                                    {imagemAtualizar}
                                    <Button ref={inputRef} style={{ marginRight: 20 }} variant="default" onClick={() => {
                                        setImagem(null);
                                        setImagemAtualizar(null);
                                        setPreviewSource(null)
                                    }}>
                                        <FaX />
                                    </Button>
                                </label>
                            }
                            {
                                imagemAtualizar == null &&
                                <input type="file" name="image" onChange={handleFileChange} />
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="titulo">Video</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={videoAtualizar}
                                onChange={onChangeVideoAtualizar}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <Select options={statusList} onChange={(e) => onChangeStatusAtualizar(e)} value={statusList.value} defaultValue={statusList[arrayUtils.getIndexArray(statusList, statusAtualizar)]} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAtualizar}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onAtualizarProduto}>
                        Atualizar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Cadastro de Produtos */}
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Adicionar Produto
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>

                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onRegistrarProduto} ref={form}>
                        <div className="form-group">
                            <label htmlFor="titulo">Titulo Produto</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={titulo}
                                onChange={onChangeTitulo}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="tipo">Tipo</label>
                            <Select options={tipoList} onChange={(e) => setTipo(e)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Categoria Produto">Categoria Produto</label>
                            <Select options={categoriaList} onChange={(e) => setCategoriaProduto(e)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descricao</label>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="endereco">Endereco</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="endereco"
                                value={endereco}
                                onChange={onChangeEndereco}
                                validations={[required]}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="endereco">Imagem</label>
                            <br></br>
                            {
                                imagem !== null &&
                                <label className="text-center" onChangeCapture={() => {
                                    setImagem(null)
                                }}>
                                    {imagem.name}
                                    <Button ref={inputRef} style={{ marginRight: 20 }} variant="default" onClick={() => {
                                        setImagem(null);
                                        setPreviewSource(null)
                                    }}>
                                        <FaX />
                                    </Button>
                                </label>
                            }
                            {
                                imagem == null &&
                                <input type="file" name="image" onChange={handleFileChange} />
                            }
                            <br></br>
                            {
                                previewSource && (
                                    <img src={previewSource} alt="chosen" style={{ height: '300px', width: '300px' }} />
                                )
                            }

                        </div>
                        <div className="form-group">
                            <label htmlFor="titulo">Video</label>
                            <small> Utilizar o link de incorporado do Youtube</small>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={video}
                                onChange={onChangeVideo}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <Select options={statusList} onChange={(e) => setStatus(e)} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onRegistrarProduto}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListarProduto
